import { v4 as generateUniqueId } from "uuid";
import { ApplicationStates } from "./ApplicationStateSchema";

/**
 * Creates the initial state.
 * @returns - A single atom representing the accumulated
 * information entered and kept throughout the form process
 */
export function createInitialState<TState extends ApplicationStates>(
    additionalState: Partial<Readonly<ApplicationStates>> = {}
): TState {
    return {
        memberOfferAvailable: false,
        spouseOfferAvailable: false,

        application: {},

        // Allow setting the session timeout values through query strings for QA
        // https://aaalife.atlassian.net/browse/DTO-138
        session: {
            sessionID: generateUniqueId(),
        },

        ...(additionalState || {}),
    } as TState;
}
