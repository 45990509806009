import styled from "styled-components";
import { devices } from "constants/breakpoints";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { InputText, Select, ErrorMessage } from "components/inputs";
import Popover from "components/popover/Popover";
import { alphaOnlyRegex, noSpecialCharactersRegex } from "constants/validations";
import { setRawHtml } from "content/setRawHtml";
import RELATIONSHIP_SELECT_OPTIONS from "constants/relationshipSelectOptions";
import { FieldError } from "components/FieldError";
import SelectNative from "components/inputs/select/SelectNative";
import { logErrors } from "utils/logErrors";
import { BeneficiariesContent } from "content/contentSchemas";

const Container = styled.div`
    display: flex;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
`;

const Label = styled.label`
    display: flex;
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
    font-weight: bold;
`;

const InputHelpText = styled.p`
    margin: 0;
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
    color: ${(props) => props.theme.colors.grey7};

    @media ${devices.minTablet} {
        margin-left: ${(props) => props.theme.spacing.padding.xs};
    }
`;

const InputCharacter = styled(InputText)`
    width: 30px;
    text-transform: uppercase;
`;

const NarrowInputGroup = styled(InputGroup)`
    width: 250px;
`;

const SplitContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media ${devices.tablet} {
        flex-direction: column;
    }
`;

const SpaceBetweenSplitContainer = styled(SplitContainer)`
    justify-content: space-between;
`;

const PaddedContainer = styled(Container)`
    padding-bottom: 8px;
    > Label {
        padding-bottom: 0;
    }
`;

export type OnSubmitArgs = BeneficiariesFormFields;

export type BeneficiariesFormInitialValues = {
    firstName?: string;
    middleInitial?: string;
    lastName?: string;
    relationship?: string;
};

export type BeneficiariesFormFields = {
    firstName?: string;
    middleInitial?: string;
    lastName?: string;
    relationship?: string;
    percentage: { value: "100"; label: "100%" };
};

export type BeneficiariesFormProps = {
    initialValues: BeneficiariesFormInitialValues;
    onSubmit: SubmitHandler<BeneficiariesFormFields>;
    onError?: SubmitErrorHandler<BeneficiariesFormFields>;
    content: BeneficiariesContent;
};

const BeneficiariesForm = ({ initialValues, onSubmit, onError, content }: BeneficiariesFormProps) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: initialValues.firstName,
            middleInitial: initialValues.middleInitial || "",
            lastName: initialValues.lastName,
            relationship: initialValues.relationship,
            percentage: { value: "100", label: "100%" },
        } as BeneficiariesFormFields,
    });

    const { relationshipPopoverHtml, percentagePopoverHtml } = content;
    const {
        requiredFieldErrorMessage,
        noSpecialCharsErrorMessage,
        firstNameMaxLength,
        middleInitialLength,
        lastNameMaxLength,
    } = content;

    const onErrorWithLogging: SubmitErrorHandler<BeneficiariesFormFields> = (validationErrors) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    return (
        <Container>
            <StyledForm
                id="beneficiaries-form"
                data-testid="beneficiaries-form"
                onSubmit={handleSubmit(onSubmit, onErrorWithLogging)}
            >
                <InputGroup>
                    <SplitContainer>
                        <Label htmlFor="beneficiary">Primary beneficiary's name</Label>
                        <InputHelpText>(First, Middle Initial, Last)</InputHelpText>
                    </SplitContainer>
                    <SpaceBetweenSplitContainer>
                        <NarrowInputGroup>
                            <InputText
                                id="firstName"
                                maxLength={20}
                                hasError={!!errors?.firstName}
                                {...register("firstName", {
                                    required: requiredFieldErrorMessage,
                                    maxLength: {
                                        value: 20,
                                        message: firstNameMaxLength,
                                    },
                                    validate: (value) =>
                                        noSpecialCharactersRegex.test(value ?? "") || noSpecialCharsErrorMessage,
                                })}
                            />
                        </NarrowInputGroup>

                        <InputGroup>
                            <InputCharacter
                                id="middleInitial"
                                maxLength={1}
                                hasError={!!errors?.middleInitial}
                                {...register("middleInitial", {
                                    minLength: {
                                        value: 1,
                                        message: middleInitialLength,
                                    },
                                    maxLength: {
                                        value: 1,
                                        message: middleInitialLength,
                                    },
                                    pattern: {
                                        value: alphaOnlyRegex,
                                        message: noSpecialCharsErrorMessage,
                                    },
                                })}
                            />
                        </InputGroup>

                        <NarrowInputGroup>
                            <InputText
                                id="lastName"
                                hasError={!!errors?.lastName}
                                maxLength={29}
                                {...register("lastName", {
                                    required: requiredFieldErrorMessage,
                                    maxLength: {
                                        value: 29,
                                        message: lastNameMaxLength,
                                    },
                                    validate: (value) =>
                                        noSpecialCharactersRegex.test(value ?? "") || noSpecialCharsErrorMessage,
                                })}
                            />
                        </NarrowInputGroup>
                    </SpaceBetweenSplitContainer>
                    <FieldError error={errors.lastName || errors.middleInitial || errors.lastName} defaultMessage="" />
                </InputGroup>

                <SpaceBetweenSplitContainer>
                    <NarrowInputGroup>
                        <PaddedContainer>
                            <Label id="relationship-label" htmlFor="relationship">
                                Beneficiary relationship
                            </Label>
                            <Popover>
                                <div {...setRawHtml(relationshipPopoverHtml)} />
                            </Popover>
                        </PaddedContainer>
                        <SelectNative
                            id="relationship"
                            placeholder="Select..."
                            aria-labelledby="relationship-label"
                            hasError={!!errors?.relationship}
                            {...register("relationship", {
                                required: requiredFieldErrorMessage,
                            })}
                        >
                            {RELATIONSHIP_SELECT_OPTIONS.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </SelectNative>
                        <FieldError error={errors.relationship} defaultMessage="" />
                    </NarrowInputGroup>

                    <NarrowInputGroup>
                        <PaddedContainer>
                            <Label id="percentage-label" htmlFor="percentage">
                                Percentage
                            </Label>
                            <Popover>
                                <div {...setRawHtml(percentagePopoverHtml)} />
                            </Popover>
                        </PaddedContainer>

                        <Controller
                            name="percentage"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    id="percentage"
                                    aria-labelledby="percentage-label"
                                    hasError={!!errors?.percentage}
                                    isDisabled
                                    options={[{ value: "100", label: "100%" }]}
                                />
                            )}
                        />
                    </NarrowInputGroup>
                </SpaceBetweenSplitContainer>

                {Object.keys(errors).length > 0 && <ErrorMessage />}
            </StyledForm>
        </Container>
    );
};

export default BeneficiariesForm;
