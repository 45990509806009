import { getMLTAOnlineQuote } from "services/onlineApplicationQuoteService";
import QuoteStartForm, {
    QuoteStartFormFields,
    QuoteStartFormSubmitCallback,
} from "features/forms/quote-start-form/QuoteStartForm";
import { QuoteStartContent } from "content/contentSchemas";
import { toLocalISODateString } from "utils/age";
import { LoyaltyQuoteStartState, QuoteStartData } from "features/pages/quote/start/QuoteStart";
import { BenefitOption } from "state/ApplicationStateSchema";
import { getClubData } from "services/cdsService";
import { ClubCodes, LoyaltyTravelAccidentPlanCodes, getLoyaltyTravelAccidentPlanCode } from "domain/planCodes";
import { DISQUALIFIED_STATES } from "constants/disqualifiedStates";

export type LoyaltyQuoteSubmitData = QuoteStartFormFields & {
    state: string;
    clubCode: string;
    planCode: LoyaltyTravelAccidentPlanCodes;
    membershipLevel: number;
    coverageOptions: BenefitOption[];
};

type Props = {
    content: QuoteStartContent;
    application: QuoteStartData;
    onError: Function;
    handleDispatch: (submittedData: LoyaltyQuoteSubmitData) => void;
};

export const LoyaltyQuoteStartContainer = ({ content, application, onError, handleDispatch }: Props) => {
    const applicationData = application.application;

    const initialValues = {
        zipCode: applicationData.zipCode,
        gender: applicationData.gender,
        email: applicationData.email,
        dateOfBirth: applicationData.dateOfBirth,
    };

    const getQuotes: QuoteStartFormSubmitCallback = async function getQuotes(submittedData) {
        try {
            const loyaltyApplication = LoyaltyQuoteStartState.parse(application);

            const clubResponse = await getClubData(submittedData.zipCode);
            const planCode = getLoyaltyTravelAccidentPlanCode(clubResponse.clubCode as ClubCodes);

            const quoteResponse = await getMLTAOnlineQuote({
                state: submittedData.state,
                dateOfBirth: toLocalISODateString(submittedData.birthDate),
                product: planCode,
                membershipLength: loyaltyApplication.membershipLength,
                tiers: loyaltyApplication.offer,
            });

            handleDispatch({
                ...submittedData,
                clubCode: clubResponse.clubCode,
                planCode: planCode,
                membershipLevel: quoteResponse.benefits[0].level, // All "benefits" should be at the same level adjusted by the quote service
                coverageOptions: quoteResponse.benefits.map(
                    (benefit): BenefitOption => ({
                        tier: benefit.tier,
                        airline: benefit.airline,
                        common: benefit.common,
                        // FUTURE: Hand name translation for inputs/outputs in the web service client abstraction or in the consumer but not both
                        lossOfLife: benefit.loss_of_life,
                        hospital: benefit.hospital,
                        emergencyRoom: benefit.yearly_er,
                        allAccident: benefit.all_accident,
                        individual: benefit.individual,
                        family: benefit.family,
                    })
                ),
            });
        } catch (error) {
            console.log(error);
            onError(error);
        }
    };

    return (
        <QuoteStartForm
            content={content}
            initialValues={initialValues}
            memberOfferAvailable={application.memberOfferAvailable}
            spouseOfferAvailable={application.spouseOfferAvailable}
            onSubmit={getQuotes}
            onError={onError}
            showEmail={true}
            showNicotine={false}
            showSpouseSelect={false}
            showGenderSelect={false}
            maxAge={100}
            disqualifiedStates={DISQUALIFIED_STATES["loyalty"]}
        />
    );
};
