import { ApplicationModeEnum } from "state/ApplicationStateSchema";
import { createCommonHeaders } from "./commonHeaders";
import { ContentArraySchema, ContentTarget, filterContent } from "utils/filterContent";

export async function getContent(
    applicationMode?: ApplicationModeEnum,
    pageName?: string,
    state?: string,
    planCode?: string,
    clubCode?: string
): Promise<ContentTarget> {
    const url = `/content/content.json`;
    const headers = {
        ...createCommonHeaders(window),
        "Content-Type": "application/json",
    };

    const response = await fetch(url, {
        method: "GET",
        headers,
    });

    if (!response.ok) {
        console.warn(response);
        throw new Error("Failed to get content");
    }

    const kvs = Object.entries({ applicationMode, pageName, state, planCode, clubCode })
        .filter(([_, v]) => !!v)
        .map(([k, v]) => [k, v || ""] as const);
    const searchTags = new Map(kvs);

    const content = await response.json();
    if (process.env.CI === "true") {
        ContentArraySchema.parse(content);
    }
    return filterContent(searchTags, content);
}
