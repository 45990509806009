import { useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Loader from "components/loader/Loader";
import { H1 } from "components/text";
import { FullPageWithBanner } from "layout/page-templates";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import { transformSubmissionData } from "utils/transformSubmissionData";
import { submit, submitMLTAApplication } from "services/onlineApplicationService";
import { DTOPageEnum } from "constants/pageNames";
import { ProcessingContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";
import { contentReplacer } from "utils/contentReplacer";
import { setRawHtml } from "content/setRawHtml";
import { RootStateSchema } from "state/RootSchema";
import { transformMLTASubmissionData } from "utils/transformMLTASubmissionData";

const TextContainer = styled.div`
    text-align: center;
    margin-bottom: 32px;
`;

const approvedUrl = "/results/approved";
const declinedUrl = "/results/declined";
const unableToFinishUrl = "/results/unable-to-finish";
const errorPageUrl = "/system-error";
const welcomeBackUrl = "/welcome-back";

const pageName = DTOPageEnum.enum.processing;
const Processing = ({ minimumWaitTimeInSeconds = 5 }) => {
    usePageTitle("Processing | AAA Life");
    useSendInteraction(pageName);

    const navigate = useNavigate();
    const { state, dispatch } = useFormState(pageName);
    const { result: content, ...contentStatus } = useContent({
        targetSchema: ProcessingContentSchema,
        applicationMode: state.applicationMode,
        pageName: "processing",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    useEffect(() => {
        const ninetySecondWaitTimeout = setTimeout(() => {
            navigate(unableToFinishUrl);
        }, 90000);

        return () => {
            clearTimeout(ninetySecondWaitTimeout);
        };
    }, [navigate]);

    const submitApplication = useCallback(async () => {
        const parsedState = RootStateSchema.parse(state);
        const genericSubmit =
            parsedState.applicationMode === "loyalty"
                ? () => {
                      const transformedData = transformMLTASubmissionData(parsedState);
                      return submitMLTAApplication(transformedData);
                  }
                : () => {
                      const transformedData = transformSubmissionData(parsedState);
                      return submit(transformedData);
                  };

        // const response = await genericSubmit();
        const [response] = await Promise.all([
            genericSubmit(),
            new Promise((resolve) => setTimeout(() => resolve("response"), minimumWaitTimeInSeconds * 1000)),
        ]);
        const policyStatus = response.policyStatus;

        dispatch({ type: "APPLICATION_PROCESSED", policyStatus });

        const outcomeUrl = toOutcomeUrlFromPolicyStatus(policyStatus);
        navigate(outcomeUrl);
    }, [state, navigate, dispatch, minimumWaitTimeInSeconds]);

    const hasAlreadyCalledSubmit = useRef(false);
    useEffect(() => {
        if (!hasAlreadyCalledSubmit.current) {
            hasAlreadyCalledSubmit.current = true;

            submitApplication().catch((error) => {
                console.error(error);
                navigate(errorPageUrl);
            });
        }
    }, [navigate, submitApplication]);

    if (!content) {
        console.warn(contentStatus?.error ?? "Failed to get content");
        return null;
    }

    const contentToReplace = { name: state?.application?.firstName };
    const title = contentReplacer(contentToReplace, content.title);
    const bodyHtml = contentReplacer(contentToReplace, content.bodyHtml);

    return (
        <FullPageWithBanner showFooterButtons={false}>
            <Loader size={60} speedMultiplier={0.22} loading={true} />
            <TextContainer>
                <H1>{title}</H1>
                {/* Consider using <BodyContainer> instead of H1 below to make it more similar to other pages */}
                <H1 {...setRawHtml(bodyHtml)} />
            </TextContainer>
        </FullPageWithBanner>
    );
};

export default Processing;

function toOutcomeUrlFromPolicyStatus(policyStatus: "approved" | "declined" | "alreadyused" | "refertounderwriter") {
    switch (policyStatus) {
        case "approved":
            return approvedUrl;
        case "declined":
            return declinedUrl;
        case "alreadyused":
            return welcomeBackUrl;
        default:
            return unableToFinishUrl;
    }
}
