export const sizes = {
    small: "630px",
    mobile: "768px",
    tablet: "834px",
    laptop: "1024px",
    desktop: "1280px",
    large: "1366px",
};

// https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries
export const devices = {
    small: `(max-width: ${sizes.small})`,
    minSmall: `(min-width: ${sizes.small})`,
    mobile: `(max-width: ${sizes.mobile})`,
    minMobile: `(min-width: ${sizes.mobile})`,
    tablet: `(max-width: ${sizes.tablet})`,
    minTablet: `(min-width: ${sizes.tablet})`,
    laptop: `(max-width: ${sizes.laptop})`,
    minLaptop: `(min-width: ${sizes.laptop})`,
    desktop: `(max-width: ${sizes.desktop})`,
    minDesktop: `(min-width: ${sizes.desktop})`,
    large: `(max-width: ${sizes.large})`,
    minLarge: `(min-width: ${sizes.large})`,
};
