import { useNavigate } from "react-router-dom";
import { PageDetails, InfoPanel } from "layout";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import { H1, H2 } from "components/text";
import { setRawHtml } from "content/setRawHtml";
import { DTOPageEnum } from "constants/pageNames";
import DirectTermQuoteResultsContainer from "./DirectTermQuoteResultsContainer";
import LoyaltyQuoteResultsContainer from "./LoyaltyQuoteResultsContainer";
import { QuoteResultsContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";
import { ApplicationModeEnumSchema, CoverageTypes } from "state/ApplicationStateSchema";
import { useEffect } from "react";
import { QuoteResultsStateSchema } from "./QuoteResultsStateSchema";
import { ageLastBirthday } from "utils/age";

type CoverageSelection = {
    coverageAmounts?: [];
    coverageAmount?: number;
    coveragePremium: number;
    coverageTier?: string;
    coverageType?: CoverageTypes;
};

const pageName = DTOPageEnum.enum.quoteResults;

const QuoteResults = () => {
    usePageTitle("Quote Results | AAA Life");
    useSendInteraction(pageName);

    const { state, dispatch } = useFormState(pageName);
    const navigate = useNavigate();
    const parseResult = QuoteResultsStateSchema.safeParse(state);

    useEffect(() => {
        if (!parseResult.success) {
            console.error(parseResult.error);
            navigate("/system-error");
        }
    });

    const { result: content } = useContent({
        targetSchema: QuoteResultsContentSchema,
        applicationMode: state.applicationMode,
        pageName: "quote-results",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    if (!parseResult.success || !parseResult.data.application || !content) {
        return null;
    }
    const data = parseResult.data;

    const handleClickBack = () => {
        navigate("/quote");
    };

    const handleClickNext = (selectedCoverage: CoverageSelection) => {
        dispatch({
            type: "COVERAGE_SELECTED",
            coverageAmounts: selectedCoverage.coverageAmounts,
            selectedCoverageAmount: selectedCoverage.coverageAmount,

            selectedCoveragePremium: selectedCoverage.coveragePremium,

            selectedCoverageTier: selectedCoverage.coverageTier,
            selectedCoverageType: selectedCoverage.coverageType,
        });

        navigate("/app/personal-info");
    };

    const onError = (formError: unknown) => {
        if (formError instanceof Error) {
            navigate("/system-error");
        }
    };

    return (
        <>
            <InfoPanel>
                <H1>{content.leftPanelTitle}</H1>
                <section {...setRawHtml(content.leftPanelBodyHtml)} />
            </InfoPanel>
            <PageDetails>
                {content.mainContentTitle && <H2>{content.mainContentTitle}</H2>}
                {data.applicationMode === ApplicationModeEnumSchema.enum.directterm ? (
                    <DirectTermQuoteResultsContainer
                        content={content}
                        placeholderValues={{
                            name: data.application?.firstName ?? undefined,
                            gender: data.application?.gender.toLowerCase(),
                            age: ageLastBirthday(new Date(data.application.dateOfBirth), new Date()).toString(),
                        }}
                        applicationData={data.application!}
                        onError={onError}
                        handleClickBack={handleClickBack}
                        handleClickNext={handleClickNext}
                    />
                ) : (
                    <LoyaltyQuoteResultsContainer
                        content={content}
                        placeholderValues={{}}
                        applicationData={data.application!}
                        onError={onError}
                        handleClickBack={handleClickBack}
                        handleClickNext={handleClickNext}
                    />
                )}
            </PageDetails>
        </>
    );
};

export default QuoteResults;
