// For local development, at run-time these aren't being added to the global window
export const {
    ENVIRONMENT_CLASS,
    ENVIRONMENT_NUMBER,

    ONLINE_APPLICATION_SERVICE_BASE_URL,
    ONLINE_APPLICATION_QUOTE_SERVICE_BASE_URL,
    CDS_SERVICE_BASE_URL,
    PAYMENT_GATEWAY_FRAME_BASE_URL,
    USER_INTERACTION_SERVICE_BASE_URL,
} = window.env || {};
