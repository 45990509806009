import { TwoColumnKnockoutPage } from "layout/page-templates";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import { DTOPageEnum } from "constants/pageNames";
import { useContent } from "hooks/useContent";
import { OopsSorryContentSchema } from "content/contentSchemas";

const pageName = DTOPageEnum.enum.oopsSorry;

const OopsSorry = () => {
    usePageTitle("Oops Sorry | AAA Life");
    useSendInteraction(pageName);

    const { state } = useFormState(pageName);

    const { result: content } = useContent({
        targetSchema: OopsSorryContentSchema,
        applicationMode: state.applicationMode,
        pageName: "oops-sorry",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    if (!state || !content) {
        return null;
    }

    return <TwoColumnKnockoutPage {...content} />;
};

export default OopsSorry;
