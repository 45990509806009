import { ONLINE_APPLICATION_SERVICE_BASE_URL } from "env";
import { createCommonHeaders } from "./commonHeaders";
import { post_Submit, post_SubmitMLTAApplication } from "./online-application-service.openapi.zod";
import { z } from "zod";

// See the following URL for OpenAPI spec information
// https://gitlab.com/aaa-life/applications/micro-services/online-application-service/-/blob/master/spec/server/online-application-service.openapi.yaml?plain=0&ref_type=heads

/** The shape of the object within the bode of the response for /findOffer */
export type FindOfferResponseBody = {
    firstName: string;
    lastName: string;
    planCode: string;
    offer: "high" | "low" | "lowest";
    memberOfferAvailable: boolean;
    spouseOfferAvailable: boolean;
    keycode: string;
    aaaMemberNumber: string;
    gender?: "M" | "F";
    memberLoyaltyYears?: number;
    memberJoinDate?: string;
};

/** Find offer details by finderNumber */
export async function findOffer(finderNumber: string): Promise<FindOfferResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/findOffer`, {
        method: "POST",
        body: JSON.stringify({ finderNumber: finderNumber }),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        if (response.status === 404) {
            console.warn(response);
            throw new Error("Unable to find offer.");
        } else {
            console.warn(response);
            throw new Error("Unexpected response to request to findOffer.");
        }
    }
    return response.json();
}

/** The shape of the address object within the body of the request for /checkIdentity */
type CheckIdentityAddress = {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
};

/** The shape of the object within the body of the request for /checkIdentity */
type CheckIdentityRequestBody = {
    firstName: string;
    middleInitial: string;
    lastName: string;
    birthDate: string;
    phone: string;
    address: CheckIdentityAddress;
    gender: "M" | "F";
    email: string;
};

/** The shape of the object within the body of the response for /checkIdentity */
type CheckIdentityResponseBody = {
    applicationID: string;
    policyNumber: string;
    hannoverId: string;
    identityCheckStatus: "approved" | "declined";
};

/** Check identity of person via the /checkIdentity endpoint */
export async function checkIdentity(person: CheckIdentityRequestBody): Promise<CheckIdentityResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/checkIdentity`, {
        method: "POST",
        body: JSON.stringify(person),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        if (response.status === 404) {
            console.warn(response);
            throw new Error("Unable to check identity.");
        } else {
            console.warn(response);
            throw new Error("Unexpected response to request to checkIdentity.");
        }
    }

    return response.json();
}

type IsEligibleApplicationRequestBody = {
    policyNumber?: string;
};

type IsEligibleApplicationResponseBody = {
    policyNumber: string;
};

/** Check eligibility for an application using the /isEligibleApplication endpoint */
export async function isEligibleApplication(
    body: IsEligibleApplicationRequestBody
): Promise<IsEligibleApplicationResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/isEligibleApplication`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        if (response.status === 404) {
            console.warn(response);
            throw new Error("Unable to check identity.");
        } else {
            console.warn(response);
            throw new Error("Unexpected response to request to isEligibleApplication.");
        }
    }

    return response.json();
}

type DirectTermSubmissionRequestBody = z.infer<typeof post_Submit.parameters.shape.body>;
type DirectTermSubmissionResponseBody = z.infer<typeof post_Submit.response>;

/** Send the application via the /submit endpoint */
export async function submit(application: DirectTermSubmissionRequestBody): Promise<DirectTermSubmissionResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/submit`, {
        method: "POST",
        body: JSON.stringify(application),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        console.warn(response);
        throw new Error("Unable to submit application.");
    }

    return post_Submit.response.parse(await response.json());
}

type LoyaltySubmissionRequestBody = z.infer<typeof post_SubmitMLTAApplication.parameters.shape.body>;
type LoyaltySubmissionResponseBody = z.infer<typeof post_SubmitMLTAApplication.response>;

/** Send the application via the /submitMLTAApplication endpoint  */
export async function submitMLTAApplication(application: LoyaltySubmissionRequestBody): Promise<LoyaltySubmissionResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/submitMLTAApplication`, {
        method: "POST",
        body: JSON.stringify(application),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        console.warn(response);
        throw new Error("Unable to submit application.");
    }

    return post_SubmitMLTAApplication.response.parse(await response.json());
}
