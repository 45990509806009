import { FullPageWithBanner } from "layout/page-templates";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import { DTOPageEnum } from "constants/pageNames";
import { WelcomeBackContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";
import { H1 } from "components/text";
import { setRawHtml } from "content/setRawHtml";
import styled from "styled-components";

const BodyContainer = styled.div`
    p {
        font-size: 18px;
    }
    h2 {
        color: ${(props) => props.theme.colors.blue};
        font-size: ${(props) => props.theme.font.size.h4};
        font-weight: bold;
        text-align: center;
        margin-top: 2em;
    }
`;

const pageName = DTOPageEnum.enum.welcomeBack;
const WelcomeBack = () => {
    usePageTitle("Welcome Back | AAA Life");
    useSendInteraction(pageName);

    const { state: data } = useFormState(pageName);
    const { result: content } = useContent({
        targetSchema: WelcomeBackContentSchema,
        applicationMode: data?.applicationMode,
        pageName: "welcome-back",
        clubCode: data?.clubSpecificData?.clubCode ?? data?.campaign?.clubCode ?? undefined,
        state: data?.application?.state ?? data?.clubSpecificData?.state,
    });

    if (!data || !content) {
        return null;
    }

    const { title, bodyHtml } = content;

    return (
        <FullPageWithBanner>
            <H1>{title}</H1>
            <BodyContainer {...setRawHtml(bodyHtml)} />
        </FullPageWithBanner>
    );
};

export default WelcomeBack;
