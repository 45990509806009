import styled from "styled-components";
import { Controller, FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { sub } from "date-fns";
import { DatePicker, RadioGroup, ErrorMessage, InputText } from "components/inputs";
import { digitOnlyRegex, emailRegex } from "constants/validations";
import { FieldError } from "components/FieldError";
import SelectNative from "components/inputs/select/SelectNative";
import { InputZipCode } from "components/inputs/zip-code/InputZipCode";
import { logErrors } from "utils/logErrors";
import { ageLastBirthday, isoDateStringToDate } from "utils/age";
import { getStateAbbreviation } from "services/cdsService";
import { toLowerCaseYesNoFromBoolean } from "utils/conversions";
import React, { ChangeEvent } from "react";
import { z } from "zod";
import { ApplicantTypeEnum } from "state/ApplicationStateSchema";
import { QuoteStartContent } from "content/contentSchemas";

const Container = styled.div`
    display: flex;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledFieldSet = styled.fieldset`
    border: none;
`;

type StyledFormProps = {
    disabled: boolean;
} & React.ComponentPropsWithoutRef<"form">;

function StyledForm(props: StyledFormProps) {
    return (
        <Form {...props}>
            <StyledFieldSet disabled={props.disabled}>{props.children}</StyledFieldSet>
        </Form>
    );
}

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
`;

const Label = styled.label`
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
    font-weight: bold;
`;

const Legend = styled.legend``;

const NarrowInputGroup = styled(InputGroup)`
    width: 250px;
`;

export type QuoteStartFormFields = {
    applicantType?: z.infer<typeof ApplicantTypeEnum>;
    gender?: "male" | "female";
    birthDate: Date;
    zipCode: string;
    hasUsedNicotineLastYear: "yes" | "no";
    email?: string;
};

const QuoteFormError = ({ errors, content }: { errors: FieldErrors<QuoteStartFormFields>; content: QuoteStartContent }) => {
    if (errors?.root?.zipCode) {
        return <ErrorMessage title={content.stateDisqualifiedTitle} bodyHtml={content.stateDisqualifiedHtml} />;
    } else if (errors?.root?.birthDate) {
        return <ErrorMessage title={content.ageDisqualifiedTitle} bodyHtml={content.ageDisqualifiedHtml} />;
    } else if (Object.keys(errors).length > 0) {
        return <ErrorMessage />;
    } else {
        return null;
    }
};

export type QuoteStartOptionalFields = {
    showEmail: boolean;
    showSpouseSelect: boolean;
    showNicotine: boolean;
    showGenderSelect: boolean;
};

export type QuoteStartFormSubmitCallback = SubmitHandler<QuoteStartFormFields & { state: string }>;

type Props = {
    content: QuoteStartContent;
    initialValues: {
        applicantType?: z.infer<typeof ApplicantTypeEnum> | null;
        zipCode?: string | null;
        gender?: "male" | "female" | null;
        hasUsedNicotineLastYear?: boolean | null;
        dateOfBirth?: string | null;
        email?: string | null;
    };
    memberOfferAvailable?: boolean;
    spouseOfferAvailable?: boolean;
    onSubmit: QuoteStartFormSubmitCallback;
    onError: Function;
    maxAge: number;
    disqualifiedStates: string[]; // FUTURE: Use UsState type instead of strings once it's available
} & QuoteStartOptionalFields;
const QuoteStartForm = ({
    content,
    initialValues = {} as Props["initialValues"],
    memberOfferAvailable,
    spouseOfferAvailable,
    onSubmit,
    onError,
    showEmail,
    showSpouseSelect,
    showNicotine,
    showGenderSelect,
    maxAge,
    disqualifiedStates,
}: Props) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
        setError,
    } = useForm<QuoteStartFormFields>({
        defaultValues: {
            zipCode: initialValues.zipCode || "",
            gender: initialValues.gender || undefined,
            hasUsedNicotineLastYear:
                toLowerCaseYesNoFromBoolean(initialValues.hasUsedNicotineLastYear ?? undefined) ?? undefined,
            applicantType: initialValues.applicantType || undefined,
            birthDate: (initialValues.dateOfBirth && isoDateStringToDate(initialValues.dateOfBirth)) || undefined,
            email: initialValues.email || undefined,
        },
    });

    const onSubmitWithKnockouts = async (
        data: QuoteStartFormFields
    ): Promise<SubmitHandler<QuoteStartFormFields> | undefined> => {
        try {
            const age = ageLastBirthday(data.birthDate, new Date());

            if (age < 18 || age > maxAge) {
                const error = {
                    type: "ageDisqualified",
                    message: content.ageDisqualifiedTitle,
                };
                setError("root.birthDate", error);
                if (onError) {
                    onError({ root: { birthDate: error } });
                }
                return;
            }
        } catch (error) {
            console.error(error);
            setError("root.birthDate", {
                type: "unableToDetermineAge",
                message: "Unable to determine age qualification.",
            });
            if (onError) {
                onError(error);
            }
            return;
        }

        try {
            const stateAbbreviation = await getStateAbbreviation(data.zipCode);

            if (disqualifiedStates.includes(stateAbbreviation)) {
                const error = {
                    type: "stateDisqualified",
                    message: content.stateDisqualifiedTitle,
                };
                setError("root.zipCode", error);
                if (onError) {
                    onError({ root: { zipCode: error } });
                }
                return;
            } else {
                onSubmit({ ...data, state: stateAbbreviation });
            }
        } catch (error) {
            console.error(error);
            setError("root.zipCode", {
                type: "unableToDetermineState",
                message: "Unable to determine state based on the ZIP code that was provided.",
            });
            if (onError) {
                onError(error);
            }
            return;
        }
    };

    const onErrorWithLogging = (validationErrors: FieldErrors<QuoteStartFormFields>) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    const validateZip = async (value: string) => {
        if (value.length !== 5) {
            return false;
        }

        if (!digitOnlyRegex.test(value)) {
            return content.invalidZipCodeCharactersMessage;
        }

        return true;
    };

    return (
        <Container>
            <StyledForm
                id="quote-start-form"
                data-testid="quote-start-form"
                onSubmit={handleSubmit(onSubmitWithKnockouts, onErrorWithLogging)}
                disabled={isSubmitting}
            >
                {/* <input type="hidden" id="state" readOnly {...register("state", { required: true })} /> */}
                {showSpouseSelect && (
                    <InputGroup>
                        <Legend>Who would you like to get a quote for?</Legend>
                        <RadioGroup
                            id="user"
                            direction="row"
                            options={[
                                {
                                    value: "member",
                                    label: "Myself",
                                    disabled: !memberOfferAvailable,
                                    withPopoverText: content.primaryUserPopoverText,
                                },
                                {
                                    value: "spouse",
                                    label: "My spouse",
                                    disabled: !spouseOfferAvailable,
                                },
                            ]}
                            {...register("applicantType", { required: content.requiredSelectErrorMessage })}
                        />
                        <FieldError error={errors.applicantType} defaultMessage={content.requiredSelectErrorMessage} />
                    </InputGroup>
                )}

                <NarrowInputGroup>
                    <Label htmlFor="birthDate">Date of birth</Label>
                    <Controller
                        name="birthDate"
                        control={control}
                        rules={{
                            required: content.invalidDateErrorMessage,
                        }}
                        render={({ field }) => (
                            <DatePicker
                                id="birthDate"
                                name="birthDate"
                                autocomplete="bday"
                                preventOpenOnFocus={true}
                                minDate={sub(new Date(), { years: 100 })}
                                maxDate={sub(new Date(), { years: 13 })}
                                onChange={(date: string) => {
                                    field.onChange(date);
                                }}
                                selected={field.value}
                                hasError={errors?.birthDate}
                            />
                        )}
                    />
                    <FieldError error={errors.birthDate} defaultMessage={content.invalidDateErrorMessage} />
                </NarrowInputGroup>

                {showGenderSelect && (
                    <NarrowInputGroup>
                        <Label id="gender-label" htmlFor="gender">
                            Gender
                        </Label>
                        <SelectNative
                            id="gender"
                            hasError={errors?.gender}
                            placeholder=""
                            {...register("gender", {
                                required: content.requiredFieldErrorMessage,
                                validate: (value: QuoteStartFormFields["gender"] | "" | undefined) =>
                                    value !== "" || content.requiredFieldErrorMessage,
                            })}
                        >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </SelectNative>
                        <FieldError error={errors.gender} defaultMessage={content.requiredFieldErrorMessage} />
                    </NarrowInputGroup>
                )}

                <NarrowInputGroup>
                    <Label htmlFor="zipCode">ZIP code</Label>
                    <InputZipCode
                        id="zipCode"
                        inputMode="numeric"
                        onInput={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length > 5) {
                                e.target.value = e.target.value.slice(0, 5);
                            }
                        }}
                        hasError={errors?.zipCode}
                        {...register("zipCode", {
                            required: content.requiredFieldErrorMessage,
                            minLength: { value: 5, message: content.zipCodeLengthErrorMessage },
                            maxLength: { value: 5, message: content.zipCodeLengthErrorMessage },
                            validate: (value) => validateZip(value),
                        })}
                    />
                    <FieldError error={errors.zipCode} defaultMessage={content.requiredFieldErrorMessage} />
                </NarrowInputGroup>

                {showNicotine && (
                    <InputGroup>
                        <Legend>{content.nicotineQuestion}</Legend>
                        <RadioGroup
                            id="hasUsedNicotineLastYear"
                            direction="row"
                            options={[
                                { value: "yes", label: "Yes" },
                                { value: "no", label: "No" },
                            ]}
                            {...register("hasUsedNicotineLastYear", { required: true })}
                        />
                        <FieldError
                            error={errors.hasUsedNicotineLastYear}
                            defaultMessage={content.requiredSelectErrorMessage}
                        />
                    </InputGroup>
                )}

                {showEmail && (
                    <NarrowInputGroup>
                        <Label htmlFor="email">Email</Label>
                        <InputText
                            id="email"
                            maxLength={100}
                            hasError={errors?.email}
                            {...register("email", {
                                maxLength: {
                                    value: 100,
                                    message: content.emailMaxLength,
                                },
                                validate: (value) =>
                                    value === undefined || value === "" || emailRegex.test(value) || content.validEmail,
                            })}
                        />
                        <FieldError error={errors.email} defaultMessage={content.validEmail} />
                    </NarrowInputGroup>
                )}

                <QuoteFormError content={content} errors={errors} />
            </StyledForm>
        </Container>
    );
};

export default QuoteStartForm;
