import styled from "styled-components";
import { devices } from "constants/breakpoints";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { InputText, InputNumber, RadioGroup, ErrorMessage } from "components/inputs";
import { noSpecialCharactersRegex, emailRegex } from "constants/validations";
import STATE_SELECT_OPTIONS from "constants/stateSelectOptions";
import PHONE_TYPE_SELECT_OPTIONS from "constants/phoneTypeSelectOptions";
import { HEIGHT_FEET_SELECT_OPTIONS, HEIGHT_INCHES_SELECT_OPTIONS } from "constants/heightSelectOptions";
import EditableField from "components/editable-field/EditableField";
import { formatUSCurrency } from "utils/formatUSCurrency";
import { formatDate, isoDateStringToDate } from "utils/age";
import { FieldError } from "components/FieldError";
import SelectNative from "components/inputs/select/SelectNative";
import { InputPhone } from "components/inputs/phone/InputPhone";
import { isPhoneFormatValid } from "utils/phone-format-validation";
import { parseFormatted10DigitPhone } from "utils/phone";
import { logErrors } from "utils/logErrors";
import { formatFullName } from "utils/formatFullName";
import { toLowerCaseYesNoFromBoolean, capitalize } from "utils/conversions";
import { createSelectOptions } from "utils/createSelectOptions";
import { PersonalInfoContent } from "content/contentSchemas";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const EditableFieldsContainer = styled.div`
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
`;

const StyledForm = styled.form<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
`;

const NarrowInputGroup = styled(InputGroup)`
    width: 250px;
`;

const SmallInputNumber = styled(InputNumber)`
    min-width: 100px;
    @media ${devices.tablet} {
        width: 100px;
    }
`;

const Label = styled.label`
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
    font-weight: bold;
`;

const SplitContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media ${devices.tablet} {
        flex-direction: column;
    }
`;

const RowContainer = styled.div`
    display: flex;
    align-items: center;
`;

const NarrowEditableField = styled.div`
    width: 250px;
`;

const InputLabelAfter = styled.p`
    margin: 0 10px 0 10px;
`;

// FUTURE: Consolidate redundant definition in other forms
const InputHelpText = styled.p`
    margin: 0;
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
    color: ${(props) => props.theme.colors.grey7};

    @media ${devices.minTablet} {
        margin-left: ${(props) => props.theme.spacing.padding.xs};
    }
`;

// FUTURE: Consider consolidating with definition in AddresseeForm
const SpaceBetweenSplitContainer = styled(SplitContainer)`
    justify-content: space-between;
`;

// FUTURE: Consider consolidating with definition in Beneficiary
const InputCharacter = styled(InputText)`
    width: 30px;
`;

type PersonalInfoFormFields = {
    firstName: string;
    middleInitial: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    zipCode: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    phone: string;
    phoneType: string;
    email: string;
    height: { feet: number; inches: number };
    weight: number;
    willReplacePolicy: "yes" | "no";
    policyToReplace: { company?: string; policyNumber?: string };
};

// Intentionally kept separate from the fields since the types, names, and structure could and should be able to be different
export type PersonalInfoFormValues = {
    applicantType: string;
    coverageAmount?: string;
    coveragePremium?: string;
    coverageTier?: string;
    coverageType: string;
    firstName: string;
    middleInitial: string;
    lastName: string;
    dateOfBirth: string;
    gender?: string;
    zipCode: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    phone: string;
    phoneType: string;
    email?: string;
    height?: { feet?: number; inches?: number };
    weight?: number;
    willReplacePolicy?: boolean;
    policyToReplace?: { company?: string; policyNumber?: string };
};

export type PersonalInfoFormProps = {
    content: PersonalInfoContent;
    initialValues: PersonalInfoFormValues;
    // FUTURE: Keep input/output value translation in one module either the form or the consumer PersonalInfoFormFields/PersonalInfoFormFields
    onSubmit: SubmitHandler<PersonalInfoFormFields>;
    onError: SubmitErrorHandler<PersonalInfoFormFields>;
    onNavigate: (url: string) => void;
    showHeightSection?: boolean;
    showWeightSection?: boolean;
    showWillReplacePolicyQuestion?: boolean;
};

const PersonalInfoForm = ({
    content,
    initialValues: {
        applicantType,
        coverageAmount,
        coveragePremium,
        coverageTier,
        coverageType,
        firstName,
        middleInitial,
        lastName,
        dateOfBirth,
        gender,
        zipCode,
        addressLine1,
        addressLine2,
        city,
        state,
        phone,
        phoneType,
        email,
        height,
        weight,
        willReplacePolicy,
        policyToReplace,
    },
    onSubmit,
    onError,
    onNavigate,
    showHeightSection = true,
    showWeightSection = true,
    showWillReplacePolicyQuestion = true,
}: PersonalInfoFormProps) => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<PersonalInfoFormFields>({
        defaultValues: {
            firstName,
            middleInitial,
            lastName,
            dateOfBirth,
            gender,
            zipCode,
            addressLine1,
            addressLine2,
            city,
            state,
            phone,
            phoneType,
            email,
            height: { feet: height?.feet, inches: height?.inches },
            weight,
            willReplacePolicy: toLowerCaseYesNoFromBoolean(willReplacePolicy) ?? undefined,
            policyToReplace: { company: policyToReplace?.company, policyNumber: policyToReplace?.policyNumber },
        },
    });

    const watchedState = watch("state");
    const watchedWillReplacePolicy = watch("willReplacePolicy");
    const selectedDisqualifiedState = watchedWillReplacePolicy === "yes" && watchedState === "FL";

    const validateOptionalInsuranceCompany = (value?: string) => {
        if (watchedWillReplacePolicy === "no") {
            return true;
        }

        if (!value) {
            return content.requiredFieldErrorMessage;
        }

        if (!noSpecialCharactersRegex.test(value)) {
            return content.noSpecialCharsErrorMessage;
        }

        return true;
    };

    const onAttemptSubmit: SubmitHandler<PersonalInfoFormFields> = (submittedData) => {
        if (selectedDisqualifiedState) {
            return;
        } else {
            onSubmit(submittedData);
        }
    };

    const onErrorWithLogging: SubmitErrorHandler<PersonalInfoFormFields> = (validationErrors) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    return (
        <Container>
            <EditableFieldsContainer>
                {coverageAmount && (
                    <EditableField
                        label={content.selectedCoverageAmountLabel}
                        value={formatUSCurrency(coverageAmount, false)}
                        onEdit={() => onNavigate("/quote/results")}
                    />
                )}
                {coverageTier && (
                    <EditableField
                        label={content.selectedTierLabel}
                        value={`${coverageTier.toUpperCase()} (${coverageType})`}
                        onEdit={() => onNavigate("/quote/results")}
                    />
                )}
                {coveragePremium && (
                    <EditableField
                        label={content.selectedPremiumAmountLabel}
                        value={formatUSCurrency(coveragePremium)}
                        onEdit={() => onNavigate("/quote/results")}
                    />
                )}
                {applicantType === "member" && (
                    <EditableField
                        label={content.applicantNameLabel}
                        value={formatFullName(firstName, middleInitial, lastName)}
                        onEdit={() => onNavigate("/")}
                        tip={content.notYouMessage}
                    />
                )}
                <EditableField
                    label={content.dateOfBirthLabel}
                    value={formatDate(isoDateStringToDate(dateOfBirth))}
                    onEdit={() => onNavigate("/quote")}
                />
                {gender && <EditableField label="Gender" value={capitalize(gender)} onEdit={() => onNavigate("/quote")} />}
            </EditableFieldsContainer>

            <StyledForm
                id="personal-info-form"
                data-testid="personal-info-form"
                onSubmit={handleSubmit(onAttemptSubmit, onErrorWithLogging)}
                // FUTURE: The form doesn't support disabled state, perhaps this was intended for a fieldset element?
                disabled={isSubmitting}
            >
                {applicantType === "spouse" && (
                    <InputGroup>
                        <SplitContainer>
                            <Label htmlFor="firstName">{content.applicantNameLabel}</Label>
                            <InputHelpText>
                                (<label htmlFor="firstName">{content.applicantFirstNameLabel}</label>,{" "}
                                <label htmlFor="middleInitial">{content.applicantMiddleInitialLabel}</label>,{" "}
                                <label htmlFor="lastName">{content.applicantLastNameLabel}</label>)
                            </InputHelpText>
                        </SplitContainer>
                        <SpaceBetweenSplitContainer>
                            <NarrowInputGroup>
                                <InputText
                                    id="firstName"
                                    autoComplete="given-name"
                                    hasError={errors?.firstName}
                                    {...register("firstName", {
                                        required: content.requiredFieldErrorMessage,
                                        maxLength: {
                                            value: 20,
                                            message: content.firstNameMaxLength,
                                        },
                                        validate: (value) =>
                                            noSpecialCharactersRegex.test(value) || content.noSpecialCharsErrorMessage,
                                    })}
                                />
                            </NarrowInputGroup>

                            <InputGroup>
                                <InputCharacter
                                    id="middleInitial"
                                    maxLength={1}
                                    hasError={errors?.middleInitial}
                                    {...register("middleInitial", {
                                        minLength: {
                                            value: 1,
                                            message: content.middleInitialLength,
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: content.middleInitialLength,
                                        },
                                        validate: (value) =>
                                            noSpecialCharactersRegex.test(value) || content.noSpecialCharsErrorMessage,
                                    })}
                                />
                            </InputGroup>

                            <NarrowInputGroup>
                                <InputText
                                    id="lastName"
                                    autoComplete="family-name"
                                    hasError={errors?.lastName}
                                    {...register("lastName", {
                                        required: content.requiredFieldErrorMessage,
                                        maxLength: {
                                            value: 29,
                                            message: content.lastNameMaxLength,
                                        },
                                        validate: (value) =>
                                            noSpecialCharactersRegex.test(value) || content.noSpecialCharsErrorMessage,
                                    })}
                                />
                            </NarrowInputGroup>
                        </SpaceBetweenSplitContainer>
                        <FieldError error={errors.firstName || errors.lastName} />
                    </InputGroup>
                )}

                <InputGroup>
                    <Label htmlFor="addressLine1">{content.streetAddressLabel}</Label>
                    <InputText
                        id="addressLine1"
                        placeholder={content.addressLine1PlaceholderText}
                        autoComplete="address-line1"
                        hasError={errors?.addressLine1}
                        maxLength={30}
                        {...register("addressLine1", {
                            required: content.requiredFieldErrorMessage,
                            maxLength: {
                                value: 30,
                                message: content.addressLine1MaxLength,
                            },
                            validate: (value) => noSpecialCharactersRegex.test(value) || content.noSpecialCharsErrorMessage,
                        })}
                    />
                </InputGroup>
                <InputGroup>
                    <InputText
                        id="addressLine2"
                        placeholder={content.addressLine2PlaceholderText}
                        autoComplete="address-line2"
                        hasError={errors?.addressLine2}
                        maxLength={30}
                        {...register("addressLine2", {
                            maxLength: {
                                value: 30,
                                message: content.addressLine2MaxLength,
                            },
                            validate: (value) => noSpecialCharactersRegex.test(value) || content.noSpecialCharsErrorMessage,
                        })}
                    />
                    <FieldError error={errors.addressLine1} />
                    <FieldError error={errors.addressLine2} />
                </InputGroup>

                <InputGroup>
                    <Label htmlFor="city">{content.cityLabel}</Label>
                    <InputText
                        id="city"
                        autoComplete="address-level2"
                        hasError={errors?.city}
                        maxLength={20}
                        {...register("city", {
                            required: content.requiredFieldErrorMessage,
                            maxLength: {
                                value: 20,
                                message: content.cityMaxLength,
                            },
                            validate: (value) => noSpecialCharactersRegex.test(value) || content.noSpecialCharsErrorMessage,
                        })}
                    />
                    <FieldError error={errors.city} />
                </InputGroup>

                <SplitContainer>
                    <NarrowInputGroup>
                        <Label id="state-label" htmlFor="state">
                            {content.stateLabel}
                        </Label>
                        <SelectNative
                            id="state"
                            aria-labelledby="state-label"
                            hasError={errors?.state}
                            {...register("state", {
                                required: content.requiredFieldErrorMessage,
                            })}
                        >
                            {createSelectOptions(STATE_SELECT_OPTIONS)}
                        </SelectNative>
                        <FieldError error={errors.state} />
                    </NarrowInputGroup>

                    <NarrowEditableField>
                        <EditableField label="ZIP code" value={zipCode} onEdit={() => onNavigate("/quote")} />
                    </NarrowEditableField>
                </SplitContainer>

                <SplitContainer>
                    <NarrowInputGroup>
                        <Label htmlFor="phone">{content.phoneNumberLabel}</Label>
                        <InputPhone
                            id="phone"
                            hasError={errors?.phone}
                            {...register("phone", {
                                required: content.requiredFieldErrorMessage,
                                minLength: {
                                    value: 10,
                                    message: content.phoneLength,
                                },
                                maxLength: {
                                    value: 10,
                                    message: content.phoneLength,
                                },
                                validate: (value) =>
                                    isPhoneFormatValid(parseFormatted10DigitPhone(value)) || content.validPhone,
                            })}
                        />
                        <FieldError error={errors.phone} />
                    </NarrowInputGroup>

                    <NarrowInputGroup>
                        <Label id="phoneType-label" htmlFor="phoneType">
                            {content.phoneTypeLabel}
                        </Label>
                        <SelectNative
                            id="phoneType"
                            aria-labelledby="phoneType-label"
                            placeholder="Select..."
                            hasError={errors?.phoneType}
                            {...register("phoneType", {
                                required: content.requiredFieldErrorMessage,
                            })}
                        >
                            {createSelectOptions(PHONE_TYPE_SELECT_OPTIONS)}
                        </SelectNative>
                        <FieldError error={errors.phoneType} />
                    </NarrowInputGroup>
                </SplitContainer>

                <InputGroup>
                    <Label htmlFor="email">{content.emailLabel}</Label>
                    <InputText
                        id="email"
                        maxLength={100}
                        hasError={errors?.email}
                        {...register("email", {
                            required: content.requiredFieldErrorMessage,
                            maxLength: {
                                value: 100,
                                message: content.emailMaxLength,
                            },
                            validate: (value) => emailRegex.test(value) || content.validEmail,
                        })}
                    />
                    <FieldError error={errors.email} />
                </InputGroup>

                {showHeightSection && (
                    <NarrowInputGroup>
                        <Label id="height-label" htmlFor="height">
                            {content.heightLabel}
                        </Label>
                        <RowContainer>
                            <SelectNative
                                id="height.feet"
                                aria-labelledby="height-label"
                                placeholder=""
                                hasError={errors?.height?.feet}
                                {...register("height.feet", {
                                    required: content.requiredFieldErrorMessage,
                                })}
                            >
                                {createSelectOptions(HEIGHT_FEET_SELECT_OPTIONS)}
                            </SelectNative>
                            <InputLabelAfter>{content.heightFeetUnitLabel}</InputLabelAfter>
                            <SelectNative
                                id="height.inches"
                                aria-labelledby="height-label"
                                placeholder=""
                                hasError={errors?.height?.inches}
                                {...register("height.inches", {
                                    required: content.requiredFieldErrorMessage,
                                })}
                            >
                                {createSelectOptions(HEIGHT_INCHES_SELECT_OPTIONS)}
                            </SelectNative>
                            <InputLabelAfter>{content.heightInchesUnitLabel}</InputLabelAfter>
                        </RowContainer>
                        <FieldError error={errors?.height?.feet || errors?.height?.inches} />
                    </NarrowInputGroup>
                )}

                {showWeightSection && (
                    <NarrowInputGroup>
                        <Label htmlFor="weight">{content.weightLabel}</Label>
                        <RowContainer>
                            <SmallInputNumber
                                id="weight"
                                min="0"
                                max="999"
                                hasError={errors?.weight}
                                {...register("weight", {
                                    required: content.requiredFieldErrorMessage,
                                    maxLength: {
                                        value: 3,
                                        message: content.weightMaxLength,
                                    },
                                })}
                            />
                            <InputLabelAfter>{content.weightPoundsUnitLabel}</InputLabelAfter>
                        </RowContainer>
                        <FieldError error={errors.weight} />
                    </NarrowInputGroup>
                )}

                {showWillReplacePolicyQuestion && (
                    <InputGroup>
                        <legend>{content.replacementPolicySentenceLabel}</legend>
                        <RadioGroup
                            id="willReplacePolicy"
                            direction="row"
                            options={[
                                { value: "yes", label: "Yes" },
                                { value: "no", label: "No" },
                            ]}
                            {...register("willReplacePolicy", { required: content.requiredSelectErrorMessage })}
                        />
                        <FieldError error={errors.willReplacePolicy} />
                    </InputGroup>
                )}

                {showWillReplacePolicyQuestion && watchedWillReplacePolicy === "yes" && (
                    <>
                        <InputGroup>
                            <Label htmlFor="policyToReplace.company">{content.replacementCompanyLabel}</Label>
                            <InputText
                                id="policyToReplace.company"
                                hasError={errors?.policyToReplace?.company}
                                maxLength={70}
                                {...register("policyToReplace.company", {
                                    maxLength: {
                                        value: 70,
                                        message: content.companyMaxLength,
                                    },
                                    validate: (value) => validateOptionalInsuranceCompany(value),
                                })}
                            />
                            <FieldError error={errors?.policyToReplace?.company} />
                        </InputGroup>

                        <InputGroup>
                            <Label htmlFor="policyToReplace.policyNumber">{content.replacementPolicyLabel}</Label>
                            <InputText
                                id="policyToReplace.policyNumber"
                                hasError={errors?.policyToReplace?.policyNumber}
                                maxLength={25}
                                {...register("policyToReplace.policyNumber", {
                                    maxLength: {
                                        value: 25,
                                        message: content.policyNumberMaxLength,
                                    },
                                    validate: (value) =>
                                        noSpecialCharactersRegex.test(value ?? "") || content.noSpecialCharsErrorMessage,
                                })}
                            />
                            <FieldError error={errors?.policyToReplace?.policyNumber} />
                        </InputGroup>
                    </>
                )}

                {Object.keys(errors).length > 0 && <ErrorMessage />}

                {selectedDisqualifiedState && (
                    <ErrorMessage title={content.stateDisqualifiedTitle} bodyHtml={content.stateDisqualifiedText} />
                )}
            </StyledForm>
        </Container>
    );
};

export default PersonalInfoForm;
