// disallow the following characters
// "(),:;<>[]&!%$+=?@
export const noSpecialCharactersRegex = /^[^"(),:;<>[\]&!%$+=?@]*$/;

export const digitOnlyRegex = /^\d+$/;

export const digitOrEmptyRegex = /^\d*$/;

export const digitOrAlphaRegex = /^[a-zA-Z0-9]$/;

export const nonDigitNorAlphaGlobalRegex = /[^a-zA-Z0-9]/g;

export const alphaOnlyRegex = /^[a-zA-Z]*$/;

export const emailRegex =
    /^[a-zA-Z0-9!#$%'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

/** Only allow a valid invitation code which is also referred to as a finder number or an application ID.*/
export const invitationCodeRegex = /^[kmKM][01][a-zA-Z0-9]{8}$/;
