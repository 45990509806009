import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { PageDetails, ButtonGroup, InfoPanel } from "layout";
import Button from "components/inputs/button/Button";
import { H1, H2 } from "components/text";
import { setRawHtml } from "content/setRawHtml";
import PaymentInfoForm, { PaymentInfoFormDefaultValues } from "features/forms/payment-info-form/PaymentInfoForm";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import CoverageSummary from "components/coverage-summary/CoverageSummary";
import { z } from "zod";
import { contentReplacer } from "utils/contentReplacer";
import { ApplicationModeEnumSchema, makeBaseApplicationSchema } from "state/ApplicationStateSchema";
import { DTOPageEnum } from "constants/pageNames";
import { PaymentInfoContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";
import { FieldErrors } from "react-hook-form";

const StyledHorizontalRule = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
    border-top: 1px solid rgb(140, 140, 140);
`;

const ApplicationStep6Schema = z.object({
    selectedCoverageAmount: z.number().nullish(),
    selectedCoveragePremium: z.number(),
    selectedCoverageTier: z.string().nullish(),
    selectedCoverageType: z.enum(["Individual", "Family"]).nullish(),

    policyNumber: z.string().min(10),
    firstName: z.string(),
    paymentToken: z.string().nullish(),
    paymentFrequency: z.enum(["Monthly"]),
});

type ApplicationStep6 = z.infer<typeof ApplicationStep6Schema>;

const PaymentInfoState = makeBaseApplicationSchema(
    ApplicationStep6Schema,
    z.object({
        applicationMode: ApplicationModeEnumSchema,
        planCode: z.string(),
    })
);

const pageName = DTOPageEnum.enum.paymentInfo;

const PaymentInfo = () => {
    usePageTitle("Payment Info | AAA Life");
    useSendInteraction(pageName);

    const navigate = useNavigate();
    const { state, dispatch } = useFormState(pageName);
    const [navigationDisabled, setNavigationDisabled] = useState(true);
    const parseResults = PaymentInfoState.safeParse(state);

    useEffect(() => {
        if (!parseResults.success) {
            console.error(parseResults.error);
            navigate("/system-error");
        }
    });

    const { result: content } = useContent({
        targetSchema: PaymentInfoContentSchema,
        applicationMode: state?.applicationMode,
        pageName: "payment-info",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    if (!parseResults.success || !content) {
        return null;
    }

    const applicationData: ApplicationStep6 = parseResults.data.application;

    const handleClickBack = () => {
        if (state.applicationMode === "loyalty") {
            navigate("/app/secondary-addressee");
        } else {
            navigate("/app/health-statement");
        }
    };

    const handleClickNext = (submittedData: { paymentToken: string }) => {
        dispatch({ type: "PAYMENT_TOKEN_RECEIVED", paymentToken: submittedData.paymentToken });
        navigate("/app/review");
    };

    const handleError: (errors?: FieldErrors<PaymentInfoFormDefaultValues> | string) => void = (formError) => {
        console.error(formError);
    };

    const handleBusy = (busy: boolean) => {
        setNavigationDisabled(busy);
    };

    // Ensure proper casing here, will likely be provided as upper case
    const leftPanelTitle = contentReplacer({ name: applicationData.firstName }, content.leftPanelTitle);

    return (
        <>
            <InfoPanel>
                <H1>{leftPanelTitle}</H1>
                <section {...setRawHtml(content.leftPanelBodyHtml)} />
            </InfoPanel>
            <PageDetails>
                <H2>{content.mainContentTitle}</H2>
                <CoverageSummary
                    productName={content.productName}
                    coverageAmount={applicationData.selectedCoverageAmount}
                    coverageTier={applicationData.selectedCoverageTier}
                    coverageType={applicationData.selectedCoverageType}
                    premium={applicationData.selectedCoveragePremium}
                    paymentFrequency={applicationData.paymentFrequency}
                />
                <StyledHorizontalRule />
                <PaymentInfoForm
                    defaultValues={{
                        paymentRefId: applicationData.policyNumber,
                        paymentToken: applicationData.paymentToken ?? undefined,
                    }}
                    onSubmit={handleClickNext}
                    onError={handleError}
                    onBusy={handleBusy}
                    content={content}
                />
                <ButtonGroup>
                    <Button label={content.previousLabel} onClick={handleClickBack} />
                    <Button
                        type="submit"
                        label={content.nextLabel}
                        color="primary"
                        form="payment-info-form"
                        disabled={navigationDisabled}
                    />
                </ButtonGroup>
            </PageDetails>
        </>
    );
};

export default PaymentInfo;
