export function toSingleLetterFromLowerCaseGender(lowerCaseGender?: string, defaultValue = null) {
    switch (lowerCaseGender) {
        case "male":
            return "M";
        case "female":
            return "F";
        default:
            return defaultValue;
    }
}
export function toLowerCaseGenderFromSingleLetter(singleLetter?: string, defaultValue = null) {
    switch (singleLetter) {
        case "M":
            return "male";
        case "F":
            return "female";
        default:
            return defaultValue;
    }
}
export function toBooleanFromLowerCaseYesNo(lowerCaseYesNo?: string, defaultValue = null) {
    switch (lowerCaseYesNo) {
        case "yes":
            return true;
        case "no":
            return false;
        default:
            return defaultValue;
    }
}
export function toLowerCaseYesNoFromBoolean(value?: boolean, defaultValue = null) {
    switch (value) {
        case true:
            return "yes";
        case false:
            return "no";
        default:
            return defaultValue;
    }
}
export function capitalize(value: string) {
    if (!value || typeof value !== "string" || value.length < 1) {
        return value;
    }

    return value.slice(0, 1).toUpperCase() + value.slice(1);
}

/**
 * Returns true for objects with a length property that has a 0 value.
 */
export function isEmpty(value: string | []) {
    return value !== null && typeof value !== "undefined" && value.hasOwnProperty("length") && value.length === 0;
}

export function emptyToDefault<T extends string | [], R>(value: T, defaultValue?: R) {
    return isEmpty(value) ? defaultValue : value;
}

export function emptyToUndefined<T extends string | []>(value: T) {
    return emptyToDefault<T, undefined>(value);
}

export function emptyToNull<T extends string | []>(value: T) {
    return emptyToDefault(value, null) ?? null;
}
