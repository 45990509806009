import { PageDetails, InfoPanel } from "layout";
import { H1, H2 } from "components/text";
import { setRawHtml } from "content/setRawHtml";

const TwoColumnKnockoutPage = ({ leftPanelTitle, leftPanelBodyHtml, title, bodyHtml }) => {
    return (
        <>
            <InfoPanel>
                <H1>{leftPanelTitle}</H1>
                <section {...setRawHtml(leftPanelBodyHtml)} />
            </InfoPanel>
            <PageDetails>
                <H2>{title}</H2>
                <section {...setRawHtml(bodyHtml)} />
            </PageDetails>
        </>
    );
};

export default TwoColumnKnockoutPage;
