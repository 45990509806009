import { USER_INTERACTION_SERVICE_BASE_URL } from "env";
import { createCommonHeaders } from "./commonHeaders";
import { interaction } from "./user-interaction-service.openapi.zod";

export { AllowedStates } from "./user-interaction-service.openapi.zod";

export type InteractionPostRequestBody = interaction;

/**
 *
 * @param {import("../utils/formatInteractionData").InteractionData} state the application state
 * @param {*} pageName the canonical name of the current page
 * @returns {Promise<Response>} the raw HTTP response
 */
export async function sendInteraction(interactionData: InteractionPostRequestBody) {
    const url = `${USER_INTERACTION_SERVICE_BASE_URL}/interaction`;
    const headers = {
        ...createCommonHeaders(window),
        "Content-Type": "application/json",
    };
    const body = JSON.stringify(interactionData);

    const response = await fetch(url, {
        method: "POST",
        body,
        headers,
    });

    if (!response.ok) {
        console.warn(response);
        throw new Error("Failed to send interaction");
    }

    return response;
}
