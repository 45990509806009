import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useContent, useFormState } from "hooks";
import { devices } from "constants/breakpoints";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import { FooterContentSchema, HeaderContentSchema } from "content/contentSchemas";
import { ReactNode } from "react";
import { toPageNameFromPathname } from "constants/pageNames";

const Container = styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const PageContent = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: ${(props) => props.theme.colors.background};

    @media ${devices.tablet} {
        flex-direction: column;
    }
`;

const HeaderAndFooterSchema = HeaderContentSchema.merge(FooterContentSchema);

type LayoutWithHeaderAndFooterProps = {
    children: ReactNode;
};

// The Outlet will render the element from the react-router route if it exists
// Optionally, render children so that we can also use this component in storybook - which has no context of routes
const LayoutWithHeaderAndFooter = ({ children }: LayoutWithHeaderAndFooterProps) => {
    const { state } = useFormState();
    const { pathname } = useLocation();
    const pagePath = toPageNameFromPathname(pathname);
    console.debug('We think the page name is "' + pagePath + '" for pathname ' + pathname);

    const { result } = useContent({
        targetSchema: HeaderAndFooterSchema,
        applicationMode: state.applicationMode,
        pageName: pagePath,
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    if (!result) {
        return null;
    }
    const content = result;

    return (
        <Container>
            <Header content={content} />
            <PageContent>
                <Outlet />
                {children}
            </PageContent>
            <Footer content={content} />
        </Container>
    );
};

export default LayoutWithHeaderAndFooter;
