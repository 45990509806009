type ComparisonResult = 1 | -1 | 0;
export function reverseCompare<T, C>(getComparableValue: (source: T) => C): (a: T, b: T) => ComparisonResult {
    return (a, b) => {
        const comparableA = getComparableValue(a);
        const comparableB = getComparableValue(b);

        if (comparableA < comparableB) {
            return 1;
        } else if (comparableA > comparableB) {
            return -1;
        } else {
            return 0;
        }
    };
}
