import { ONLINE_APPLICATION_QUOTE_SERVICE_BASE_URL } from "env";
import { createCommonHeaders } from "./commonHeaders";
import { LoyaltyTierEnum, LoyaltyTierEnumSchema } from "state/ApplicationStateSchema";
import { z } from "zod";

export type OnlineQuoteRequestBody = {
    state: string;
    gender: string;
    day: number;
    month: number;
    year: number;
    useNicotine: boolean;
    productCode: string;
    zipCode: string;
};

type Quote = {
    coverageAmount: number;
    totalPremiumAmount: number;
};

export type OnlineQuoteResponseBody = {
    quotes: Quote[];
};

export async function getDirectTermOnlineQuote(body: OnlineQuoteRequestBody): Promise<OnlineQuoteResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_QUOTE_SERVICE_BASE_URL}/direct-term-online-quote`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            ...createCommonHeaders(window),
            accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    if (response.ok) {
        return await response.json(); //NOSONAR
    } else if (response.status === 404) {
        console.warn(response);
        throw new Error("Unable to find quotes.");
    } else {
        console.warn(response);
        throw new Error("Unexpected response to request to find quotes.");
    }
}

type LoyaltyOfferRequestBody = {
    dateOfBirth: string;
    membershipLength: number;
    state: string;
    product: "MLTA" | "ACLTA";
    tiers: LoyaltyTierEnum[];
};

const LoyaltyBenefitSchema = z.object({
    tier: LoyaltyTierEnumSchema,
    level: z.number(),
    airline: z.number(),
    common: z.number(),
    loss_of_life: z.number(),
    hospital: z.number(),
    yearly_er: z.number(),
    all_accident: z.number(),
    state: z.string(),
    minAge: z.number(),
    maxAge: z.number().optional(),
    individual: z.number(),
    family: z.number(),
});

const LoyaltyOfferResponseBodySchema = z.object({
    benefits: z.array(LoyaltyBenefitSchema),
});

export type LoyaltyOfferResponseBody = z.infer<typeof LoyaltyOfferResponseBodySchema>;

export async function getMLTAOnlineQuote(body: LoyaltyOfferRequestBody): Promise<LoyaltyOfferResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_QUOTE_SERVICE_BASE_URL}/mlta-online-quote`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            ...createCommonHeaders(window),
            accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    if (response.ok) {
        const json = await response.json(); //NOSONAR
        return LoyaltyOfferResponseBodySchema.parse(json);
    } else if (response.status === 404) {
        console.warn(response);
        throw new Error("Unable to find quotes.");
    } else {
        console.warn(response);
        throw new Error("Unexpected response to request to find quotes.");
    }
}
