import { getPageInteractionDefaults } from "constants/pageInteractionDefaults";
import { toSingleLetterFromLowerCaseGender } from "utils/conversions";
import { DTOPages } from "constants/pageNames";
import { ApplicationModeEnum, ApplicationStates } from "state/ApplicationStateSchema";
import { AllowedStates, InteractionPostRequestBody } from "services/userInteractionService";

/**
 * Creates an object that adheres to the /interaction endpoint's payload schema
 */
export function formatInteractionData(state: ApplicationStates, pageName: DTOPages): InteractionPostRequestBody {
    const interactionMetadata = getPageInteractionDefaults(state.applicationMode, pageName);

    if (typeof interactionMetadata === "undefined") {
        throw new Error("Invalid disposition code");
    }

    const productSpecificProperties = getProductSpecificValues(state.applicationMode);

    const loyaltyTravelAccidentProperties =
        state.applicationMode === "loyalty"
            ? {
                  coverageType: state.application?.selectedCoverageType ?? undefined,
                  tier: state.application?.selectedCoverageTier ?? undefined,
              }
            : {};

    const termProperties =
        state.applicationMode === "directterm"
            ? {
                  termPeriod: 20, // For Direct Term we assume the term is 20 years for now
              }
            : {};

    return {
        ...{
            formNumber: undefined, // FUTURE: Get from findOffer and perhaps quote or compute based on state / clubCode (M0-178)
            aaaMember: true, // Always true for DTO member and spouse as well as MLTA/ACLTA
            // aaaMemberNumber: state.aaaMemberNumber ?? undefined, // FUTURE: Uncomment once security / letter C hack is fixed
            policyNumber: state?.application?.policyNumber ?? undefined,
            applicationId: state?.application?.applicationID ?? state.invitationCode ?? undefined,
            firstName: limitStringLength(state.application?.firstName || state.firstName, 20) ?? undefined,
            lastName: limitStringLength(state.application?.lastName || state.lastName, 29) ?? undefined,
            middleName: limitStringLength(state.application?.middleInitial || state.middleInitial, 15) ?? undefined,
            gender: toSingleLetterFromLowerCaseGender(state.application?.gender) ?? undefined,
            phone: state.application?.phone,
            phoneType: state.application?.phoneType,
            email: state.application?.email,
            paymentFrequencyMode: state.application?.paymentFrequency === "Monthly" ? "RECURRING_MONTHLY" : undefined,

            coverageAmount: state.application?.selectedCoverageAmount ?? undefined,
            modalPremiumAmount: state.application?.selectedCoveragePremium ?? undefined,
            address: {
                addressLine1: state.application?.addressLine1,
                addressLine2: state.application?.addressLine2 ?? undefined,
                city: state.application?.city,
                state: (state.application?.state as AllowedStates) ?? state.clubSpecificData?.state,
                zipCode: state.application?.zipCode,
            },
            birthDate: state.application?.dateOfBirth,
            nicotineUse: state.application?.hasUsedNicotineLastYear,
            weight: state.application?.weight ?? undefined,
            heightInTotalInches: state.application?.height
                ? state.application.height.feet + state.application.height.inches * 12
                : undefined,
        },
        interactionCode: interactionMetadata.interactionCode,
        dispositionCode: interactionMetadata.dispositionCode,
        isFinalInteraction: interactionMetadata.isFinalInteraction,
        interactionLevel:
            typeof interactionMetadata.interactionLevel !== "undefined" ? interactionMetadata.interactionLevel : 1,
        sessionId: state.session?.sessionID,
        url: window.location.href,
        visitId: undefined, // FUTURE: This value can come from the VISITID_AAA cookie if present but isn't required yet for DTO
        clubCode: state.clubSpecificData?.clubCode ?? state?.application?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        leadSource: state?.campaign?.leadSource ?? undefined,
        leadRouting: undefined, // FUTURE: Comes from the lead query string parameter
        campaignCode: state?.campaign?.campaignCode ?? undefined,
        campaignUrl: state?.campaign?.campaignUrl ?? undefined,
        product: productSpecificProperties.product,
        planCode: state.clubSpecificData?.planCode ?? state?.planCode ?? undefined,
        channelCode: productSpecificProperties.channelCode,
        primaryClientSourceSystemId: productSpecificProperties.systemIdCode,
        coverageType: loyaltyTravelAccidentProperties.coverageType,
        // We trust tier values for now as we trust the backend systems where the originate for now and do not manage the values
        tier: loyaltyTravelAccidentProperties.tier as "Value" | "Economy" | "Deluxe" | "Ultra" | "Superior" | "Premier",
        ...termProperties,
    };
}

/**
 * Safely limits the length of the specified string value in the case that it is valid string otherwise the original value
 * is returned unmodified.
 * @param {string | undefined | null} value
 * @param {number} newLength
 * @returns {string | undefined | null} A string limited to the number of characters specified if provided otherwise the
 * original value is returned.
 */
function limitStringLength(value: string | null | undefined, newLength: number): string | null | undefined {
    return typeof value === "string" ? value.substring(0, newLength) : value;
}

function getProductSpecificValues(applicationMode: ApplicationModeEnum): {
    product: string;
    channelCode: string;
    systemIdCode: string;
} {
    switch (applicationMode) {
        case "directterm":
            return {
                product: "DMT_ONLINE_GROUP",
                channelCode: "DM",
                systemIdCode: "DMTO",
            };
        case "loyalty":
            return {
                product: "MLTA_ONLINE",
                channelCode: "DM", // Should we use WB or DM
                systemIdCode: "MLO",
            };
    }
}
