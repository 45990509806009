import styled from "styled-components";
import { FullPageWithBanner } from "layout/page-templates";
import { usePageTitle, useFormCompletion, useSendInteraction } from "hooks";
import { H1 } from "components/text";
import { setRawHtml } from "content/setRawHtml";
import { contentReplacer } from "utils/contentReplacer";
import { DTOPageEnum, PagesEnumSchema } from "constants/pageNames";
import { DeclinedContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";

const BodyContainer = styled.div`
    p {
        font-size: 18px;
    }
`;

const pageName = DTOPageEnum.enum.declined;
const Declined = () => {
    usePageTitle("Declined | AAA Life");
    useSendInteraction(pageName);

    const [data] = useFormCompletion(pageName, (formState) => ({
        name: formState?.application?.firstName,
        policy: formState?.application?.policyNumber ?? undefined,

        applicationMode: formState?.applicationMode,
        clubCode: formState?.clubSpecificData?.clubCode ?? formState?.campaign?.clubCode ?? undefined,
        state: formState?.application?.state ?? formState?.clubSpecificData?.state,
    }));

    const { result: content } = useContent({
        targetSchema: DeclinedContentSchema,
        applicationMode: data.applicationMode,
        pageName: PagesEnumSchema.enum.declined,
        clubCode: data.clubCode,
        state: data.state,
    });

    if (!data || !content) {
        return null;
    }

    const contentToReplace = { name: data.name, policy: data.policy };
    const title = contentReplacer(contentToReplace, content.title);
    const bodyHtml = contentReplacer(contentToReplace, content.bodyHtml);

    return (
        <FullPageWithBanner>
            <H1>{title}</H1>
            <BodyContainer {...setRawHtml(bodyHtml)} />
        </FullPageWithBanner>
    );
};

export default Declined;
