import { z } from "zod";

type UsStates = string; // FUTURE: Use a union of all valid state codes once MO-26 is completed

export type ClubCodes =
    | "001"
    | "004"
    | "005"
    | "006"
    | "007"
    | "014"
    | "018"
    | "020"
    | "023"
    | "036"
    | "047"
    | "049"
    | "057"
    | "065"
    | "069"
    | "071"
    | "080"
    | "084"
    | "104"
    | "111"
    | "113"
    | "130"
    | "137"
    | "160"
    | "176"
    | "177"
    | "188"
    | "195"
    | "212"
    | "215"
    | "219"
    | "222"
    | "238"
    | "240"
    | "241"
    | "252"
    | "258"
    | "260"
    | "270"
    | "601";

export const DirectTermPlanCodesSchema = z.enum(["DMAT22", "DMMT22", "DMNY22", "DMWA22"]);
export type DirectTermPlanCodes = z.infer<typeof DirectTermPlanCodesSchema>;

/** Note that these are not the accurate underlying plan codes but instead are placeholder values which are handled by FAST
 * and then translated to the appropriate plan codes.
 */
export const LoyaltyTravelAccidentPlanCodesSchema = z.enum(["MLTA", "ACLTA"]);
export type LoyaltyTravelAccidentPlanCodes = z.infer<typeof LoyaltyTravelAccidentPlanCodesSchema>;

export function getDirectTermPlanCode(state: UsStates): DirectTermPlanCodes {
    switch (state) {
        case "WA":
        case "MA":
            return "DMWA22";
        case "MT":
            return "DMMT22";
        case "NY":
            return "DMNY22";
        case "FL":
        case "ID":
        case "KS":
        case "ME":
        case "MN":
        case "NC":
        case "NH":
        case "OH":
        case "VT":
        default:
            return "DMAT22";
    }
}

export function getLoyaltyTravelAccidentPlanCode(clubCode: ClubCodes): LoyaltyTravelAccidentPlanCodes {
    if (clubCode === "004") {
        return "ACLTA";
    } else {
        return "MLTA";
    }
}
