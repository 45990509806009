import { TwoColumnKnockoutPage } from "layout/page-templates";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import { DTOPageEnum } from "constants/pageNames";
import { CheckBackContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";

const pageName = DTOPageEnum.enum.checkBack;
const CheckBack = () => {
    usePageTitle("Check Back | AAA Life");
    useSendInteraction(pageName);

    const { state } = useFormState(pageName);

    const data = state;
    const { result: content } = useContent({
        targetSchema: CheckBackContentSchema,
        applicationMode: data?.applicationMode,
        pageName: "check-back",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    if (!state || !content) {
        return null;
    }

    return <TwoColumnKnockoutPage {...content} />;
};

export default CheckBack;
