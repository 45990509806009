import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { devices } from "constants/breakpoints";
import { Container, ButtonGroup } from "layout";
import Button from "components/inputs/button/Button";
import HeaderImage from "assets/images/DTO_HeaderImage_Large.jpg";
import { H2 } from "components/text";

const OuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

// These combined stop the image from growing exponentially on larger screens
// Removing align-self: center will cause the image NOT to properly shrink for smaller viewpoints
// The max-width gives the image a nicer resolution on larger displays
const StyledImage = styled.img`
    @media ${devices.minLarge} {
        max-width: 1350px;
        align-self: center;
    }
`;

const PageContent = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 30px;
`;

const ButtonGroupContainer = styled.div`
    @media ${devices.minTablet} {
        padding-bottom: 30px;
    }

    @media ${devices.tablet} {
        padding-bottom: 14px;
    }
`;

const FooterTitle = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    border-top: 1px solid ${(props) => props.theme.colors.grey5};

    @media ${devices.minTablet} {
        padding-top: 30px;
    }

    @media ${devices.tablet} {
        padding-top: 14px;
    }
`;

const StyledLink = styled.a`
    text-decoration: none;
`;

const FullPageWithBanner = ({ showFooterButtons = true, children }) => {
    const navigate = useNavigate();

    const handleClickRestart = () => {
        navigate("/?uid");
    };

    return (
        <OuterWrapper>
            <StyledImage src={HeaderImage} alt="" />
            <PageContent>{children}</PageContent>
            {showFooterButtons && (
                <>
                    <FooterTitle>
                        <H2>Would you like to begin another application?</H2>
                    </FooterTitle>
                    <ButtonGroupContainer>
                        <ButtonGroup border={false}>
                            <StyledLink href="https://www.aaalife.com">
                                <Button label="Return to AAALife.com" />
                            </StyledLink>
                            <Button label="Begin Another App" color="primary" onClick={handleClickRestart} />
                        </ButtonGroup>
                    </ButtonGroupContainer>
                </>
            )}
        </OuterWrapper>
    );
};

export default FullPageWithBanner;
