import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PageDetails, InfoPanel, ButtonGroup } from "layout";
import { H1, H2 } from "components/text";
import { Button } from "components/inputs";
import InvitationCodeForm, { OnSubmitArgs } from "features/forms/invitation-code-form/InvitationCodeForm";
import { useFormState, usePageTitle, useSendInteraction, useLandingPageUrl } from "hooks";
import { setRawHtml } from "content/setRawHtml";
import { DTOPageEnum } from "constants/pageNames";
import { useContent } from "hooks/useContent";
import { TaskFallback } from "components/TaskFallback";
import { WelcomeContentSchema } from "content/contentSchemas";
import Loader from "components/loader/Loader";
import { ApplicationModeEnumSchema } from "state/ApplicationStateSchema";
import { toLowerCaseGenderFromSingleLetter } from "utils/conversions";

const HorizontalRule = styled.hr`
    margin-top: 2em;
    margin-bottom: 1em;
`;

const pageName = DTOPageEnum.enum.welcome;

function pipedOffersToArray(offers: string) {
    return offers.split("|");
}

const WelcomePage = () => {
    usePageTitle("Welcome | AAA Life");
    useSendInteraction(pageName);

    const { state, dispatch } = useFormState(pageName);

    const { result: content, ...contentStatus } = useContent({
        targetSchema: WelcomeContentSchema,
        applicationMode: state.applicationMode,
        pageName: "welcome",
        clubCode: state.clubSpecificData?.clubCode ?? state.campaign?.clubCode ?? undefined,
    });

    const navigate = useNavigate();

    const landingPageParameters = useLandingPageUrl();
    useEffect(() => {
        dispatch({
            type: "LANDING_PAGE_VISITED",
            ...{ url: landingPageParameters.url, params: landingPageParameters.params },
        });
    }, [dispatch, landingPageParameters]);

    const handleClick = async ({ invitationCode, offer }: OnSubmitArgs) => {
        dispatch({
            type: "OFFER_FOUND",
            invitationCode: invitationCode,
            firstName: offer.firstName,
            lastName: offer.lastName,
            planCode: offer.planCode,
            offer:
                state.applicationMode === ApplicationModeEnumSchema.enum.loyalty
                    ? pipedOffersToArray(offer.offer)
                    : offer.offer,
            keyCode: offer.keycode,
            aaaMemberNumber: offer.aaaMemberNumber,
            memberOfferAvailable: offer.memberOfferAvailable,
            spouseOfferAvailable: offer.spouseOfferAvailable,
            membershipLength: offer.memberLoyaltyYears,
            memberSince: offer.memberJoinDate,
            gender: toLowerCaseGenderFromSingleLetter(offer.gender) ?? undefined,
        });

        if (!offer.memberOfferAvailable && !offer.spouseOfferAvailable) {
            // No offers are available
            navigate("/welcome-back");
            return;
        }

        if (state.applicationMode === "loyalty" && !("memberLoyaltyYears" in offer && "memberJoinDate" in offer)) {
            console.error("Required offer properties were not provided for loyalty offer.");
            navigate("/system-error");
            return;
        }

        navigate("/quote");
    };

    return (
        <TaskFallback fallback={<Loader />} errorFallback={<div>ERROR LOADING CONTENT</div>} {...contentStatus}>
            {content && (
                <>
                    <InfoPanel>
                        <H1>{content.leftPanelTitle}</H1>
                        <section {...setRawHtml(content.leftPanelBodyHtml)} />
                        {content.leftPanelFooterHtml && (
                            <>
                                <HorizontalRule />
                                <footer {...setRawHtml(content.leftPanelFooterHtml)} />
                            </>
                        )}
                    </InfoPanel>
                    <PageDetails>
                        <H2>{content.pageTitle}</H2>
                        <InvitationCodeForm
                            content={content}
                            onSubmit={handleClick}
                            invitationCode={state.invitationCode ?? undefined}
                        />
                        <ButtonGroup>
                            <Button type="submit" label={content.nextLabel} color="primary" form="invitation-code-form" />
                        </ButtonGroup>
                    </PageDetails>
                </>
            )}
        </TaskFallback>
    );
};

export default WelcomePage;
