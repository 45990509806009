import styled from "styled-components";
import { devices } from "constants/breakpoints";
import Container from "layout/Container";
import { ReactComponent as AAALogo } from "assets/images/logo.svg";
import { setRawHtml } from "content/setRawHtml";
import { HeaderContent } from "content/contentSchemas";

const StyledHeader = styled.header`
    height: 80px;
    display: flex;
    align-items: center;

    background-color: ${(props) => props.theme.colors.background};
    border-bottom: 1px solid ${(props) => props.theme.colors.grey6};

    font-size: ${(props) => props.theme.font.size.text};

    @media ${devices.minTablet} {
        padding-left: ${(props) => props.theme.spacing.padding.large};
        padding-right: ${(props) => props.theme.spacing.padding.large};
    }

    @media ${devices.tablet} {
        padding: ${(props) => props.theme.spacing.padding.small};
    }
`;

const AlignMiddleContainer = styled(Container)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const StyledLogo = styled(AAALogo)`
    max-height: 60px;
    width: auto;
`;

const InfoContainer = styled.div`
    display: flex;
    align-items: center;

    @media ${devices.tablet} {
        flex-direction: column;
        align-items: end;
    }
`;

const BoldText = styled.p`
    margin: 0;
    color: ${(props) => props.theme.colors.blue};
    font-weight: ${(props) => props.theme.font.weight.bold};
`;

const MinorText = styled.p`
    @media ${devices.minDesktop} {
        color: ${(props) => props.theme.colors.blue};
    }

    @media ${devices.desktop} {
        display: none;
    }
`;

const Divider = styled.span`
    color: ${(props) => props.theme.colors.red};

    @media ${devices.minTablet} {
        padding-left: ${(props) => props.theme.spacing.padding.xs};
        padding-right: ${(props) => props.theme.spacing.padding.xs};
    }

    @media ${devices.tablet} {
        display: none;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

const HelpText = styled.p`
    margin: 0;
`;

const PhoneNumber = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.blue};
    font-weight: ${(props) => props.theme.font.weight.bold};
`;

type HeaderProps = {
    content: HeaderContent;
};

const Header = ({ content }: HeaderProps) => {
    const { productName, headerSubtitleText, headerHelpText, phoneNumberText } = content;
    return (
        <StyledHeader>
            <AlignMiddleContainer>
                <StyledLogo />
                <InfoContainer>
                    <BoldText>{productName}</BoldText>
                    <MinorText>:&nbsp;{headerSubtitleText}</MinorText>
                    <Divider>|</Divider>
                    <FlexContainer>
                        <HelpText {...setRawHtml(headerHelpText)} />
                        &nbsp;
                        <PhoneNumber href={`tel:${phoneNumberText}`}>{phoneNumberText}</PhoneNumber>
                    </FlexContainer>
                </InfoContainer>
            </AlignMiddleContainer>
        </StyledHeader>
    );
};

export default Header;
