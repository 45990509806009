import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import { PageDetails, ButtonGroup, InfoPanel } from "layout";
import Button from "components/inputs/button/Button";
import { H1 } from "components/text";
import ReviewData from "./ReviewData";
import ReviewHeader from "./ReviewHeader";
import { ReviewApplicationStateSchema } from "./ReviewApplicationStateSchema";
import { formatFullName } from "utils/formatFullName";
import { capitalize, toLowerCaseYesNoFromBoolean } from "utils/conversions";
import { formatDate, isoDateStringToDate } from "utils/age";
import { formatUSCurrency } from "utils/formatUSCurrency";
import { format10DigitNanpaPhone } from "utils/phone";
import { setRawHtml } from "content/setRawHtml";
import { DTOPageEnum } from "constants/pageNames";
import { useContent } from "hooks/useContent";
import { ReviewContent, ReviewContentSchema } from "content/contentSchemas";
import { useEffect, useMemo } from "react";
import { z } from "zod";
import { SecondaryAddresseeSchema, StrictSecondaryAddresseeSchema } from "state/ApplicationStateSchema";

const ReviewSection = styled.div`
    padding-bottom: ${(props) => props.theme.spacing.padding.large};
`;

type Height = { feet: number; inches: number };
const prettyHeight = ({ feet, inches }: Height) => `${feet} ft. ${inches} in.`;

type FullName = Partial<{ firstName: string; middleInitial?: string | null; lastName: string }>;
const prettyName = (person: FullName) => formatFullName(person.firstName, person.middleInitial, person.lastName);
const prettyCoverageTier = (selectedCoverageTier: string, selectedCoverageType: string) =>
    `${selectedCoverageTier.toUpperCase()} (${selectedCoverageType})`;
// FUTURE: Remove null options once schema is adjusted for Review step where these values should all be defined
type Address = Partial<{
    addressLine1: string | null;
    addressLine2?: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
}>;
const prettyAddress = (person: Address) => (
    <>
        {person.addressLine1}
        <br />
        {person.addressLine2 && (
            <>
                {person.addressLine2}
                <br />
            </>
        )}
        {capitalize(person.city!)}, {person.state} {person.zipCode}
        <br />
    </>
);

const Schema = ReviewApplicationStateSchema;

const pageName = DTOPageEnum.enum.review;
const Review = () => {
    usePageTitle("Review | AAA Life");
    useSendInteraction(pageName);

    const navigate = useNavigate();
    const { state } = useFormState(pageName);
    const parseResult = useMemo(() => Schema.safeParse(state), [state]);

    const { result: content } = useContent({
        targetSchema: ReviewContentSchema,
        applicationMode: state?.applicationMode,
        pageName: "review",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    useEffect(() => {
        if (!parseResult.success) {
            console.error(JSON.stringify(parseResult.error, null, 4));
            navigate("/system-error");
        }
    }, [parseResult, navigate]);

    if (!parseResult.success || !parseResult.data.application || !content) {
        return null;
    }

    const applicationData = parseResult.data.application;

    const handleClickBack = () => {
        navigate("/app/payment-info");
    };

    const handleClickNext = () => {
        navigate("/app/consent");
    };

    return (
        <>
            <InfoPanel>
                <H1>{content.leftPanelTitle}</H1>
                <section {...setRawHtml(content.leftPanelBodyHtml)} />
            </InfoPanel>
            <PageDetails>
                <ReviewSection>
                    <ReviewHeader title={content.applicantInformationSectionHeader} linkTo="/app/personal-info" />
                    <ReviewData title="Name" data={prettyName(applicationData)} />
                    <ReviewData title="Home address" data={prettyAddress(applicationData)} isAddress={true} />
                    {applicationData.gender && <ReviewData title="Gender" data={capitalize(applicationData.gender)} />}
                    <ReviewData title="Date of birth" data={formatDate(isoDateStringToDate(applicationData.dateOfBirth!))} />
                    <ReviewData title="Email address" data={applicationData.email!} />
                    <ReviewData title="Phone number" data={format10DigitNanpaPhone(applicationData.phone)} />
                    {applicationData.height && <ReviewData title="Height" data={prettyHeight(applicationData.height)} />}
                    {applicationData.weight && <ReviewData title="Weight" data={`${applicationData.weight} lbs`} />}
                </ReviewSection>
                <ReviewSection>
                    <ReviewHeader title={content.coverageSelectionSectionHeader} linkTo="/quote/results" />
                    {applicationData.selectedCoverageAmount && (
                        <ReviewData
                            title={content.selectedCoverageAmountLabel}
                            data={formatUSCurrency(applicationData.selectedCoverageAmount, false)}
                        />
                    )}
                    {applicationData.selectedCoverageTier && applicationData.selectedCoverageType && (
                        <ReviewData
                            title={content.selectedTierLabel}
                            data={prettyCoverageTier(
                                applicationData.selectedCoverageTier,
                                applicationData.selectedCoverageType
                            )}
                        />
                    )}
                    {applicationData.selectedCoveragePremium && (
                        <ReviewData
                            title={content.selectedPremiumAmountLabel}
                            data={formatUSCurrency(applicationData.selectedCoveragePremium)}
                        />
                    )}
                </ReviewSection>
                {applicationData.beneficiary && (
                    <ReviewSection>
                        <ReviewHeader title={content.beneficiaryInformationSectionHeader} linkTo="/app/beneficiaries" />

                        <ReviewData title="Beneficiary name" data={prettyName(applicationData.beneficiary)} />
                        <ReviewData
                            title="Beneficiary relationship"
                            data={capitalize(applicationData?.beneficiary?.relationship)}
                        />
                        <ReviewData title="Allocation" data={`${applicationData?.beneficiary?.percentage}%`} />
                    </ReviewSection>
                )}
                <SecondaryAddresseeSection
                    {...({
                        content: content,
                        designatedSecondaryAddressee: applicationData.designatedSecondaryAddressee,
                        secondaryAddressee: applicationData.secondaryAddressee,
                    } as SecondaryAddresseeSectionProps)}
                />
                {state.applicationMode === "directterm" && (
                    <>
                        <ReplacementQuestionSection content={content} applicationData={applicationData} />
                        <HealthQuestionSections content={content} applicationData={applicationData} />
                    </>
                )}
                <ButtonGroup border={false}>
                    <Button label={content.previousLabel} onClick={handleClickBack} />
                    <Button label={content.nextLabel} color="primary" onClick={handleClickNext} />
                </ButtonGroup>
            </PageDetails>
        </>
    );
};

export default Review;

type SecondaryAddresseeStrict = {
    designatedSecondaryAddressee: true;
    secondaryAddressee: z.infer<typeof StrictSecondaryAddresseeSchema>;
};

type SecondaryAddresseePartial = {
    designatedSecondaryAddressee: false;
    secondaryAddressee?: z.infer<typeof SecondaryAddresseeSchema>;
};

// FUTURE: For now we make a localized discriminated union for secondary addressee strictness but we should push this
// behavior if possible into the definition of the state schema itself. Doing so may require
type SecondaryAddresseeSectionProps = { content: ReviewContent } & (SecondaryAddresseeStrict | SecondaryAddresseePartial);

function SecondaryAddresseeSection({
    content,
    designatedSecondaryAddressee,
    secondaryAddressee,
}: SecondaryAddresseeSectionProps) {
    const notApplicable = "N/A";
    return (
        <ReviewSection>
            <ReviewHeader title={content.secondaryAddresseeSectionHeader} linkTo="/app/secondary-addressee" />

            <ReviewData
                title="Secondary addressee name"
                data={designatedSecondaryAddressee ? prettyName(secondaryAddressee) : notApplicable}
            />
            <ReviewData
                title="Address"
                data={designatedSecondaryAddressee ? prettyAddress(secondaryAddressee) : notApplicable}
                isAddress={true}
            />
            <ReviewData
                title="Phone number"
                data={designatedSecondaryAddressee ? format10DigitNanpaPhone(secondaryAddressee.phone) : notApplicable}
            />
        </ReviewSection>
    );
}

type ReplacementQuestionSectionProps = {
    content: ReviewContent;
    // FUTURE: Tighten these types up using a DU based on willReplacePolicy later
    applicationData: {
        willReplacePolicy?: boolean | null;
        policyToReplace?: {
            company?: string | null;
            policyNumber?: string | null;
        } | null;
    };
};

function ReplacementQuestionSection({ content, applicationData }: ReplacementQuestionSectionProps) {
    const replacingPolicyNotApplicable = !applicationData.willReplacePolicy && "N/A";
    return (
        <ReviewSection>
            <ReviewHeader title={content.replacementPolicySectionHeader} linkTo="/app/personal-info" />

            <ReviewData
                title="Insurer"
                data={replacingPolicyNotApplicable || applicationData?.policyToReplace?.company || "N/A"}
            />
            <ReviewData
                title="Policy number"
                data={replacingPolicyNotApplicable || applicationData?.policyToReplace?.policyNumber || "Unspecified"}
            />
        </ReviewSection>
    );
}

type HealthQuestionSectionsProps = {
    content: ReviewContent;
    applicationData: {
        hasUsedNicotineLastYear?: boolean;
        hasSevereMedicalConditions?: boolean;
        hadDiagnosticTesting?: boolean;
    };
};

function HealthQuestionSections({ content, applicationData }: HealthQuestionSectionsProps) {
    return (
        <ReviewSection>
            <ReviewHeader title={content.statementOfHealthSectionHeader} linkTo="/app/health-statement" />

            <ReviewData
                index={1}
                title={content.nicotineQuestion}
                data={capitalize(toLowerCaseYesNoFromBoolean(applicationData.hasUsedNicotineLastYear)!)}
            />
            <ReviewData
                index={2}
                title={<span {...setRawHtml(content.severeMedicalQuestionHtml)} />}
                data={capitalize(toLowerCaseYesNoFromBoolean(applicationData.hasSevereMedicalConditions)!)}
            />
            <ReviewData
                index={3}
                title={content.diagnosticTestingQuestion}
                data={capitalize(toLowerCaseYesNoFromBoolean(applicationData.hadDiagnosticTesting)!)}
            />
        </ReviewSection>
    );
}
