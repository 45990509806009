import styled from "styled-components";
import { devices } from "constants/breakpoints";

const setBorder = (props) => props.border && `border-top: 1px solid ${props.theme.colors.grey5}`;

const Container = styled.div`
    ${setBorder};

    display: flex;
    align-items: center;
    justify-content: center;

    @media ${devices.tablet} {
        flex-direction: column;
    }
`;

const ButtonGroup = ({ children, border = true }) => {
    return <Container border={border}>{children}</Container>;
};

export default ButtonGroup;
