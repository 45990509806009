import z from "zod";

export type ServiceErrorResponse = z.infer<typeof ServiceErrorResponse>;
export const ServiceErrorResponse = z.object({
    status: z.number(),
    title: z.string(),
    detail: z.union([z.string(), z.undefined()]).optional(),
});

export type interaction = z.infer<typeof interaction>;
export const interaction = z.object({
    channelCode: z.union([z.string(), z.undefined()]).optional(),
    coverageAmount: z.union([z.number(), z.undefined()]).optional(),
    dispositionCode: z.union([z.string(), z.undefined()]).optional(),
    email: z.union([z.string(), z.undefined()]).optional(),
    gender: z.union([z.literal("M"), z.literal("F"), z.undefined()]).optional(),
    interactionCode: z.union([z.string(), z.undefined()]).optional(),
    firstName: z.union([z.string(), z.undefined()]).optional(),
    middleName: z.union([z.string(), z.undefined()]).optional(),
    lastName: z.union([z.string(), z.undefined()]).optional(),
    paymentFrequencyMode: z
        .union([
            z.literal("SINGLE_MONTHLY"),
            z.literal("SINGLE_QUARTERLY"),
            z.literal("SINGLE_SEMIANNUAL"),
            z.literal("SINGLE_ANNUAL"),
            z.literal("RECURRING_MONTHLY"),
            z.literal("RECURRING_QUARTERLY"),
            z.literal("RECURRING_SEMIANNUAL"),
            z.literal("RECURRING_ANNUAL"),
            z.literal("MONTHLY"),
            z.literal("RECURRING"),
            z.literal("SINGLE"),
            z.undefined(),
        ])
        .optional(),
    paymentType: z
        .union([
            z.literal("CC"),
            z.literal("CK"),
            z.literal("ET"),
            z.literal("EFT"),
            z.literal("GA"),
            z.literal("IN"),
            z.literal("NN"),
            z.literal("OT"),
            z.literal("WP"),
            z.undefined(),
        ])
        .optional(),
    planCode: z.union([z.string(), z.undefined()]).optional(),
    policyNumber: z.union([z.string(), z.undefined()]).optional(),
    primaryClientSourceSystemId: z.union([z.string(), z.undefined()]).optional(),
    product: z.union([z.string(), z.undefined()]).optional(),
    aaaMember: z.union([z.boolean(), z.undefined()]).optional(),
    aaaMemberNumber: z.union([z.string(), z.undefined()]).optional(),
    address: z
        .union([
            z.object({
                addressLine1: z.string().optional(),
                addressLine2: z.string().optional(),
                city: z.string().optional(),
                state: z
                    .union([
                        z.literal("AL"),
                        z.literal("AK"),
                        z.literal("AZ"),
                        z.literal("AR"),
                        z.literal("CA"),
                        z.literal("CO"),
                        z.literal("CT"),
                        z.literal("DE"),
                        z.literal("DC"),
                        z.literal("FL"),
                        z.literal("GA"),
                        z.literal("HI"),
                        z.literal("ID"),
                        z.literal("IL"),
                        z.literal("IN"),
                        z.literal("IA"),
                        z.literal("KS"),
                        z.literal("KY"),
                        z.literal("LA"),
                        z.literal("ME"),
                        z.literal("MD"),
                        z.literal("MA"),
                        z.literal("MI"),
                        z.literal("MN"),
                        z.literal("MS"),
                        z.literal("MO"),
                        z.literal("MT"),
                        z.literal("NE"),
                        z.literal("NV"),
                        z.literal("NH"),
                        z.literal("NJ"),
                        z.literal("NM"),
                        z.literal("NY"),
                        z.literal("NC"),
                        z.literal("ND"),
                        z.literal("OH"),
                        z.literal("OK"),
                        z.literal("OR"),
                        z.literal("PA"),
                        z.literal("RI"),
                        z.literal("SC"),
                        z.literal("SD"),
                        z.literal("TN"),
                        z.literal("TX"),
                        z.literal("UT"),
                        z.literal("VT"),
                        z.literal("VA"),
                        z.literal("WA"),
                        z.literal("WV"),
                        z.literal("WI"),
                        z.literal("WY"),
                    ])
                    .optional(),
                zipCode: z.string().optional(),
            }),
            z.undefined(),
        ])
        .optional(),
    campaignCode: z.union([z.string(), z.undefined()]).optional(),
    campaignUrl: z.union([z.string(), z.undefined()]).optional(),
    channelId: z.union([z.number(), z.undefined()]).optional(),
    creativeId: z.union([z.string(), z.undefined()]).optional(),
    birthDate: z.union([z.string(), z.undefined()]).optional(),
    emailSource: z.union([z.string(), z.undefined()]).optional(),
    healthRating: z
        .union([z.literal("Excellent"), z.literal("Very Good"), z.literal("Good"), z.literal("Fair"), z.undefined()])
        .optional(),
    nicotineUse: z.union([z.boolean(), z.undefined()]).optional(),
    modalPremiumAmount: z.union([z.number(), z.undefined()]).optional(),
    termPeriod: z.union([z.number(), z.undefined()]).optional(),
    weight: z.union([z.number(), z.undefined()]).optional(),
    heightInTotalInches: z.union([z.number(), z.undefined()]).optional(),
    ipAddress: z.union([z.string(), z.undefined()]).optional(),
    leadRouting: z.union([z.literal("club"), z.literal("DSU"), z.undefined()]).optional(),
    leadSource: z.union([z.string(), z.undefined()]).optional(),
    pageId: z.union([z.string(), z.undefined()]).optional(),
    phone: z.union([z.string(), z.undefined()]).optional(),
    phoneSource: z.union([z.string(), z.undefined()]).optional(),
    phoneType: z
        .union([z.literal("Home"), z.literal("Business"), z.literal("Business Fax"), z.literal("Mobile"), z.undefined()])
        .optional(),
    clubCode: z.union([z.string(), z.undefined()]).optional(),
    riskClass: z
        .union([
            z.literal("Standard Non-Nicotine"),
            z.literal("Standard Plus Non-Nicotine"),
            z.literal("Preferred Non-Nicotine"),
            z.literal("Super Preferred Non-Nicotine"),
            z.literal("Super Preferred Nicotine"),
            z.literal("Standard Nicotine"),
            z.literal("Preferred Nicotine"),
            z.undefined(),
        ])
        .optional(),
    visitorId: z.union([z.string(), z.undefined()]).optional(),
    visitId: z.union([z.string(), z.undefined()]).optional(),
    sessionId: z.string(),
    url: z.union([z.string(), z.undefined()]).optional(),
    applicationId: z.union([z.string(), z.undefined()]).optional(),
    isFinalInteraction: z.union([z.boolean(), z.undefined()]).optional(),
    interactionLevel: z.union([z.number(), z.undefined()]).optional(),
    coverageType: z.union([z.literal("Family"), z.literal("Individual"), z.undefined()]).optional(),
    tier: z
        .union([
            z.literal("Value"),
            z.literal("Economy"),
            z.literal("Deluxe"),
            z.literal("Ultra"),
            z.literal("Superior"),
            z.literal("Premier"),
            z.undefined(),
        ])
        .optional(),
});

export type AllowedStates = z.infer<typeof AllowedStates>;
export const AllowedStates = z.union([
    z.literal("AL"),
    z.literal("AK"),
    z.literal("AZ"),
    z.literal("AR"),
    z.literal("CA"),
    z.literal("CO"),
    z.literal("CT"),
    z.literal("DE"),
    z.literal("DC"),
    z.literal("FL"),
    z.literal("GA"),
    z.literal("HI"),
    z.literal("ID"),
    z.literal("IL"),
    z.literal("IN"),
    z.literal("IA"),
    z.literal("KS"),
    z.literal("KY"),
    z.literal("LA"),
    z.literal("ME"),
    z.literal("MD"),
    z.literal("MA"),
    z.literal("MI"),
    z.literal("MN"),
    z.literal("MS"),
    z.literal("MO"),
    z.literal("MT"),
    z.literal("NE"),
    z.literal("NV"),
    z.literal("NH"),
    z.literal("NJ"),
    z.literal("NM"),
    z.literal("NY"),
    z.literal("NC"),
    z.literal("ND"),
    z.literal("OH"),
    z.literal("OK"),
    z.literal("OR"),
    z.literal("PA"),
    z.literal("RI"),
    z.literal("SC"),
    z.literal("SD"),
    z.literal("TN"),
    z.literal("TX"),
    z.literal("UT"),
    z.literal("VT"),
    z.literal("VA"),
    z.literal("WA"),
    z.literal("WV"),
    z.literal("WI"),
    z.literal("WY"),
]);

export type post_Interaction = typeof post_Interaction;
export const post_Interaction = {
    method: z.literal("POST"),
    path: z.literal("/interaction"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
        }),
        body: z.object({
            channelCode: z.union([z.string(), z.undefined()]).optional(),
            coverageAmount: z.union([z.number(), z.undefined()]).optional(),
            dispositionCode: z.union([z.string(), z.undefined()]).optional(),
            email: z.union([z.string(), z.undefined()]).optional(),
            gender: z.union([z.literal("M"), z.literal("F"), z.undefined()]).optional(),
            interactionCode: z.union([z.string(), z.undefined()]).optional(),
            firstName: z.union([z.string(), z.undefined()]).optional(),
            middleName: z.union([z.string(), z.undefined()]).optional(),
            lastName: z.union([z.string(), z.undefined()]).optional(),
            paymentFrequencyMode: z
                .union([
                    z.literal("SINGLE_MONTHLY"),
                    z.literal("SINGLE_QUARTERLY"),
                    z.literal("SINGLE_SEMIANNUAL"),
                    z.literal("SINGLE_ANNUAL"),
                    z.literal("RECURRING_MONTHLY"),
                    z.literal("RECURRING_QUARTERLY"),
                    z.literal("RECURRING_SEMIANNUAL"),
                    z.literal("RECURRING_ANNUAL"),
                    z.literal("MONTHLY"),
                    z.literal("RECURRING"),
                    z.literal("SINGLE"),
                    z.undefined(),
                ])
                .optional(),
            paymentType: z
                .union([
                    z.literal("CC"),
                    z.literal("CK"),
                    z.literal("ET"),
                    z.literal("EFT"),
                    z.literal("GA"),
                    z.literal("IN"),
                    z.literal("NN"),
                    z.literal("OT"),
                    z.literal("WP"),
                    z.undefined(),
                ])
                .optional(),
            planCode: z.union([z.string(), z.undefined()]).optional(),
            policyNumber: z.union([z.string(), z.undefined()]).optional(),
            primaryClientSourceSystemId: z.union([z.string(), z.undefined()]).optional(),
            product: z.union([z.string(), z.undefined()]).optional(),
            aaaMember: z.union([z.boolean(), z.undefined()]).optional(),
            aaaMemberNumber: z.union([z.string(), z.undefined()]).optional(),
            address: z
                .union([
                    z.object({
                        addressLine1: z.string().optional(),
                        addressLine2: z.string().optional(),
                        city: z.string().optional(),
                        state: z
                            .union([
                                z.literal("AL"),
                                z.literal("AK"),
                                z.literal("AZ"),
                                z.literal("AR"),
                                z.literal("CA"),
                                z.literal("CO"),
                                z.literal("CT"),
                                z.literal("DE"),
                                z.literal("DC"),
                                z.literal("FL"),
                                z.literal("GA"),
                                z.literal("HI"),
                                z.literal("ID"),
                                z.literal("IL"),
                                z.literal("IN"),
                                z.literal("IA"),
                                z.literal("KS"),
                                z.literal("KY"),
                                z.literal("LA"),
                                z.literal("ME"),
                                z.literal("MD"),
                                z.literal("MA"),
                                z.literal("MI"),
                                z.literal("MN"),
                                z.literal("MS"),
                                z.literal("MO"),
                                z.literal("MT"),
                                z.literal("NE"),
                                z.literal("NV"),
                                z.literal("NH"),
                                z.literal("NJ"),
                                z.literal("NM"),
                                z.literal("NY"),
                                z.literal("NC"),
                                z.literal("ND"),
                                z.literal("OH"),
                                z.literal("OK"),
                                z.literal("OR"),
                                z.literal("PA"),
                                z.literal("RI"),
                                z.literal("SC"),
                                z.literal("SD"),
                                z.literal("TN"),
                                z.literal("TX"),
                                z.literal("UT"),
                                z.literal("VT"),
                                z.literal("VA"),
                                z.literal("WA"),
                                z.literal("WV"),
                                z.literal("WI"),
                                z.literal("WY"),
                            ])
                            .optional(),
                        zipCode: z.string().optional(),
                    }),
                    z.undefined(),
                ])
                .optional(),
            campaignCode: z.union([z.string(), z.undefined()]).optional(),
            campaignUrl: z.union([z.string(), z.undefined()]).optional(),
            channelId: z.union([z.number(), z.undefined()]).optional(),
            creativeId: z.union([z.string(), z.undefined()]).optional(),
            birthDate: z.union([z.string(), z.undefined()]).optional(),
            emailSource: z.union([z.string(), z.undefined()]).optional(),
            healthRating: z
                .union([z.literal("Excellent"), z.literal("Very Good"), z.literal("Good"), z.literal("Fair"), z.undefined()])
                .optional(),
            nicotineUse: z.union([z.boolean(), z.undefined()]).optional(),
            modalPremiumAmount: z.union([z.number(), z.undefined()]).optional(),
            termPeriod: z.union([z.number(), z.undefined()]).optional(),
            weight: z.union([z.number(), z.undefined()]).optional(),
            heightInTotalInches: z.union([z.number(), z.undefined()]).optional(),
            ipAddress: z.union([z.string(), z.undefined()]).optional(),
            leadRouting: z.union([z.literal("club"), z.literal("DSU"), z.undefined()]).optional(),
            leadSource: z.union([z.string(), z.undefined()]).optional(),
            pageId: z.union([z.string(), z.undefined()]).optional(),
            phone: z.union([z.string(), z.undefined()]).optional(),
            phoneSource: z.union([z.string(), z.undefined()]).optional(),
            phoneType: z
                .union([
                    z.literal("Home"),
                    z.literal("Business"),
                    z.literal("Business Fax"),
                    z.literal("Mobile"),
                    z.undefined(),
                ])
                .optional(),
            clubCode: z.union([z.string(), z.undefined()]).optional(),
            riskClass: z
                .union([
                    z.literal("Standard Non-Nicotine"),
                    z.literal("Standard Plus Non-Nicotine"),
                    z.literal("Preferred Non-Nicotine"),
                    z.literal("Super Preferred Non-Nicotine"),
                    z.literal("Super Preferred Nicotine"),
                    z.literal("Standard Nicotine"),
                    z.literal("Preferred Nicotine"),
                    z.undefined(),
                ])
                .optional(),
            visitorId: z.union([z.string(), z.undefined()]).optional(),
            visitId: z.union([z.string(), z.undefined()]).optional(),
            sessionId: z.string(),
            url: z.union([z.string(), z.undefined()]).optional(),
            applicationId: z.union([z.string(), z.undefined()]).optional(),
            isFinalInteraction: z.union([z.boolean(), z.undefined()]).optional(),
            interactionLevel: z.union([z.number(), z.undefined()]).optional(),
            coverageType: z.union([z.literal("Family"), z.literal("Individual"), z.undefined()]).optional(),
            tier: z
                .union([
                    z.literal("Value"),
                    z.literal("Economy"),
                    z.literal("Deluxe"),
                    z.literal("Ultra"),
                    z.literal("Superior"),
                    z.literal("Premier"),
                    z.undefined(),
                ])
                .optional(),
        }),
    }),
    response: z.unknown(),
};

// <EndpointByMethod>
export const EndpointByMethod = {
    post: {
        "/interaction": post_Interaction,
    },
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type PostEndpoints = EndpointByMethod["post"];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
    body?: unknown;
    query?: Record<string, unknown>;
    header?: Record<string, unknown>;
    path?: Record<string, unknown>;
};

export type MutationMethod = "post" | "put" | "patch" | "delete";
export type Method = "get" | "head" | MutationMethod;

export type DefaultEndpoint = {
    parameters?: EndpointParameters | undefined;
    response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
    operationId: string;
    method: Method;
    path: string;
    parameters?: TConfig["parameters"];
    meta: {
        alias: string;
        hasParameters: boolean;
        areParametersRequired: boolean;
    };
    response: TConfig["response"];
};

type Fetcher = (method: Method, url: string, parameters?: EndpointParameters | undefined) => Promise<Endpoint["response"]>;

type RequiredKeys<T> = {
    [P in keyof T]-?: undefined extends T[P] ? never : P;
}[keyof T];

type MaybeOptionalArg<T> = RequiredKeys<T> extends never ? [config?: T] : [config: T];

// </ApiClientTypes>

// <ApiClient>
export class ApiClient {
    baseUrl: string = "";

    constructor(public fetcher: Fetcher) {}

    setBaseUrl(baseUrl: string) {
        this.baseUrl = baseUrl;
        return this;
    }

    // <ApiClient.post>
    post<Path extends keyof PostEndpoints, TEndpoint extends PostEndpoints[Path]>(
        path: Path,
        ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
    ): Promise<z.infer<TEndpoint["response"]>> {
        return this.fetcher("post", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
    }
    // </ApiClient.post>
}

export function createApiClient(fetcher: Fetcher, baseUrl?: string) {
    return new ApiClient(fetcher).setBaseUrl(baseUrl ?? "");
}

/**
 Example usage:
 const api = createApiClient((method, url, params) =>
   fetch(url, { method, body: JSON.stringify(params) }).then((res) => res.json()),
 );
 api.get("/users").then((users) => console.log(users));
 api.post("/users", { body: { name: "John" } }).then((user) => console.log(user));
 api.put("/users/:id", { path: { id: 1 }, body: { name: "John" } }).then((user) => console.log(user));
*/

// </ApiClient
