import styled from "styled-components";
import { FieldError as HookFieldError } from "react-hook-form";

const ErrorText = styled.span`
    color: red;
`;

type ValidationError = {
    error: HookFieldError | undefined;
    defaultMessage?: string;
};

export function FieldError({ error, defaultMessage }: ValidationError) {
    if (!error) {
        return null;
    }
    const message = error.message || defaultMessage;
    let errorRefId;
    if (error.ref) {
        const ref = error.ref;
        // accept name due to react-hook-form controller not passing in element ref, but accepting a name property
        errorRefId = ("id" in ref && ref.id) || ref.name;
    }

    return (
        // Consider adding aria-describedBy on the field pointing to this error id
        <ErrorText role="alert" id={errorRefId && `${errorRefId}__error`}>
            {message}
        </ErrorText>
    );
}
